import Repository from '@infinity/shared/repos/base'
import { Api } from '@infinity/shared/helpers/api'
import ValidationResults from '@/models/ValidationResults'

export default class ValidationResultsRepo extends Repository {
  constructor () {
    super(Api.IM, 'validation-results', ValidationResults, 'data')
  }

  async getResults (installationId: string, filename: string) {
    const results = await this.withParams({ installationId, filename }).find('')

    if (results instanceof ValidationResults) {
      return results
    }

    return null
  }

  get requestConfig (): { headers: { [k: string]: string | null } } | null {
    const headers = super.requestConfig
    if (!headers) {
      return null
    }

    // Remove as this violates IM CORS
    delete headers.headers['x-auth-token']

    return headers
  }
}
