import Model, { ApiFieldDefinition, apiToModelTransformer, modelToApiTransformer } from '@infinity/shared/models/base'
import { Api, JsonData } from '@infinity/shared/helpers/api'

export default class TrackingPool extends Model {
  // TODO: Use Data Dictionary
  protected fieldDefinition: ApiFieldDefinition = {
    [Api.Hub]: {
      dgrp: 'id',
      dgrpName: 'name',
      igrp: 'installationId'
    }
  }

  private id = ''
  private name = ''
  private installationId = ''

  getId (): string {
    return this.id
  }

  setId (id: string): TrackingPool {
    this.id = id

    return this
  }

  getName (): string {
    return this.name
  }

  setName (name: string): TrackingPool {
    this.name = name

    return this
  }

  getInstallationId (): string {
    return this.installationId
  }

  setInstallationId (installationId: string): TrackingPool {
    this.installationId = installationId

    return this
  }

  fromApiTransformer (data: JsonData, api: Api): TrackingPool {
    apiToModelTransformer(this, data, this.fieldDefinition[api])

    return this
  }

  toApiTransformer (api: Api): JsonData {
    return modelToApiTransformer(this, this.fieldDefinition[api])
  }
}
