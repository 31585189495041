import Model, { ApiFieldDefinition, apiToModelTransformer, modelToApiTransformer } from '@infinity/shared/models/base'
import { Api, JsonData } from '@infinity/shared/helpers/api'

export default class CsvFile extends Model {
  // TODO: Use Data Dictionary
  protected fieldDefinition: ApiFieldDefinition = {
    [Api.IM]: {
      installationId: 'installationId',
      headers: 'headers',
      rows: 'rows'
    }
  }

  private installationId = ''
  private headers: Array<string> = []
  private rows: Array<Array<string>> = []

  getInstallationId (): string {
    return this.installationId
  }

  setInstallationId (installationId: string): CsvFile {
    this.installationId = installationId

    return this
  }

  getHeaders (): Array<string> {
    return this.headers
  }

  setHeaders (headers: Array<string> = []): CsvFile {
    this.headers = headers

    return this
  }

  getRows (): Array<Array<string>> {
    return this.rows
  }

  setRows (rows: Array<Array<string>> = []): CsvFile {
    this.rows = rows

    return this
  }

  addRow (row: Array<string>) {
    this.rows.push(row)

    return this
  }

  fromApiTransformer (data: JsonData, api: Api): CsvFile {
    apiToModelTransformer(this, data, this.fieldDefinition[api])

    return this
  }

  toApiTransformer (api: Api): JsonData {
    return modelToApiTransformer(this, this.fieldDefinition[api])
  }
}
