























































import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import { FieldConfig, FieldMapping, FormatConfig, DatetimeFormats, Timezones } from '@/views/Mapping.vue'
import { FormSelectPlugin, BIconTrashFill } from 'bootstrap-vue'

Vue.use(FormSelectPlugin)

@Component({
  components: {
    BIconTrashFill
  }
})
export default class FieldMap extends Vue {
  @Prop({ required: true, type: Object })
  mapping!: FieldMapping

  @Prop({ required: true, type: Array })
  availableFields!: FieldConfig[]

  @Prop({ required: true, type: Array })
  availableHeaders!: string[]

  @Prop({ required: true, type: Object })
  formatConfig!: FormatConfig

  get preview () {
    if (this.$store.state.csvFile) {
      const headerIndex = this.$store.state.csvFile?.getHeaders()?.indexOf(this.mapping.header)

      if (headerIndex > -1) {
        for (const value of this.$store.state.csvFile.getRows()) {
          if (value[headerIndex]) {
            return value[headerIndex]
          }
        }
      }
    }
  }

  getAvailableDatetimeFormats (selectedDatetimeFormat: string | undefined) {
    return DatetimeFormats.filter(
      (datetimeFormat: string) => {
        return datetimeFormat !== selectedDatetimeFormat
      }
    )
  }

  getAvailableTimezones (selectedTimezone: string | null) {
    return Timezones.filter(
      (timezone: string) => {
        return timezone !== selectedTimezone
      }
    )
  }

  updateField (fieldConfig: FieldConfig) {
    Vue.set(this.mapping, 'field', fieldConfig)
  }

  updateHeader (header: string) {
    Vue.set(this.mapping, 'header', header)
  }

  updateDatetimeFormat (datetimeFormat: string) {
    Vue.set(this.mapping, 'datetimeFormat', datetimeFormat)
    Vue.set(this.formatConfig, 'datetimeFormat', datetimeFormat)
    Vue.set(this.formatConfig, 'callStartDatetimeFormat', datetimeFormat)
  }

  updatePhoneNumberCountryCode (countryCode: string) {
    Vue.set(this.formatConfig, 'phoneNumberCountryCode', countryCode)
  }

  updateTimezone (timezone: string) {
    Vue.set(this.formatConfig, 'timezone', timezone)
  }

  @Emit('delete')
  doDelete () {
    return this.mapping
  }
}
