




import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class SortIcon extends Vue {
  @Prop({ type: Boolean, default: false })
  sortDesc!: boolean

  @Prop({ type: Boolean, default: false })
  active!: boolean

  get ascOpacity () {
    if (!this.active || this.sortDesc) {
      return '0.3'
    }

    return '1'
  }

  get descOpacity () {
    if (!this.active || !this.sortDesc) {
      return '0.3'
    }

    return '1'
  }
}
