

























import { Component, Vue } from 'vue-property-decorator'
import { BIcon, BIconCheck2Square, BIconCloudUploadFill, BIconXSquare, BIconFiles, LayoutPlugin } from 'bootstrap-vue'
import { IntelligentMatchStatus } from '@/store'

Vue.use(LayoutPlugin)

@Component({
  components: {
    BIconCloudUploadFill,
    BIconXSquare,
    BIconCheck2Square,
    BIconFiles,
    BIcon
  }
})
export default class Status extends Vue {
  get icon (): string {
    if (this.$store.state.status === IntelligentMatchStatus.Failed) {
      return 'x-square'
    }

    return 'check2-square'
  }

  get iconVariant (): string | undefined {
    if (this.$store.state.status === IntelligentMatchStatus.Success) {
      return 'success'
    }

    if (this.$store.state.status === IntelligentMatchStatus.Failed) {
      return 'danger'
    }
  }
}
