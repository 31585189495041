import Model, { ApiFieldDefinition, apiToModelTransformer } from '@infinity/shared/models/base'
import { Api, JsonData } from '@infinity/shared/helpers/api'
import ValidationMetadata from '@/models/ValidationMetadata'
import { isObject } from 'lodash'

export default class ValidationResults extends Model {
  protected fieldDefinition: ApiFieldDefinition = {
    [Api.IM]: {
      url: 'url',
      filename: 'filename',
      batchId: 'batchId'
    }
  }

  private url = ''
  private filename = ''
  private batchId = ''
  private metadata = new ValidationMetadata()

  getUrl (): string {
    return this.url
  }

  setUrl (value: string) {
    this.url = value
  }

  getFilename (): string {
    return this.filename
  }

  setFilename (value: string) {
    this.filename = value
  }

  getBatchId (): string {
    return this.batchId
  }

  setBatchId (batchId: string) {
    this.batchId = batchId
  }

  getMetadata (): ValidationMetadata {
    return this.metadata
  }

  setMetadata (value: ValidationMetadata) {
    this.metadata = value
  }

  fromApiTransformer (data: JsonData, api: Api): ValidationResults {
    apiToModelTransformer(this, data, this.fieldDefinition[api])

    if (isObject(data.metadata)) {
      this.metadata = new ValidationMetadata()
        .fromApiTransformer(data.metadata as JsonData, api)
    }

    return this
  }

  toApiTransformer (): undefined {
    return undefined
  }
}
