var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _vm.hasBooted
        ? [
            _c(
              "b-container",
              { staticClass: "d-flex align-items-center flex-column" },
              [
                !_vm.excludeNavigation
                  ? _c("Navigation", { staticClass: "mt-3" })
                  : _vm._e(),
                _c(
                  "p",
                  { staticClass: "bg-light mt-3 p-3 rounded-lg text-whale" },
                  [
                    _vm._v(
                      " Smart Match provides an easy way to link CRM events to the corresponding visitor and their associated marketing source, letting you link transactions to the marketing activities that drove them. Simply upload a CSV file with Caller ID, Date & Time, and transaction details, and we’ll match them. "
                    )
                  ]
                ),
                _vm.$route.name && _vm.onUploadRoute(_vm.$route.name)
                  ? _c("Status", { staticClass: "mt-4" })
                  : _vm._e(),
                _c("router-view", {
                  on: { "go-to-external-route": _vm.goToExternalRoute }
                })
              ],
              1
            )
          ]
        : _vm._e(),
      _c("b-toaster", { attrs: { name: "b-toaster-top-right" } })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }