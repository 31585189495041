var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "w-100" },
    [
      _c(
        "b-row",
        {
          staticClass: "ml-0 mb-3 text-center",
          attrs: { "align-v": "center" }
        },
        [
          _c(
            "b-col",
            { staticClass: "p-0", attrs: { cols: "5" } },
            [
              _c(
                "b-input-group",
                [
                  _c(
                    "b-input-group-prepend",
                    [
                      _c(
                        "b-input-group-text",
                        {
                          staticClass: "bg-white pr-1",
                          staticStyle: { "border-right": "none" }
                        },
                        [
                          _c("b-icon-search", {
                            attrs: { "font-scale": "0.9", scale: "1.1" }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("b-form-input", {
                    directives: [
                      {
                        name: "test",
                        rawName: "v-test",
                        value: "filter",
                        expression: "'filter'"
                      }
                    ],
                    staticStyle: { "border-left": "none" },
                    attrs: {
                      placeholder: "Filter by keyword...",
                      type: "search",
                      debounce: "500"
                    },
                    model: {
                      value: _vm.filter,
                      callback: function($$v) {
                        _vm.filter = $$v
                      },
                      expression: "filter"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm.type === _vm.CheckboxGroupType.Pools
            ? _c(
                "b-col",
                { staticClass: "ml-auto", attrs: { cols: "5" } },
                [
                  _c(
                    "b-checkbox",
                    {
                      attrs: { switch: "" },
                      on: { change: _vm.toggleExcludeFixedNumbers },
                      model: {
                        value: _vm.preferences.excludeFixedNumbers,
                        callback: function($$v) {
                          _vm.$set(_vm.preferences, "excludeFixedNumbers", $$v)
                        },
                        expression: "preferences.excludeFixedNumbers"
                      }
                    },
                    [_vm._v("Exclude Fixed Numbers From Pools")]
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "w-100 bg-light p-3 rounded-lg bordered checkbox-group"
        },
        [
          _vm.isLoading
            ? _c(
                "div",
                { staticClass: "d-flex flex-column align-items-center" },
                [_c("b-spinner")],
                1
              )
            : _c(
                "b-form-group",
                { staticClass: "mb-0" },
                [
                  _vm.options.length > 0
                    ? _c(
                        "b-form-checkbox",
                        {
                          staticClass: "mb-3",
                          attrs: { indeterminate: _vm.indeterminate },
                          on: { change: _vm.toggleAll },
                          model: {
                            value: _vm.allSelected,
                            callback: function($$v) {
                              _vm.allSelected = $$v
                            },
                            expression: "allSelected"
                          }
                        },
                        [
                          _vm._v(
                            " Select All Current " + _vm._s(_vm.type) + " "
                          )
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "b-form-checkbox-group",
                    {
                      directives: [
                        {
                          name: "test",
                          rawName: "v-test",
                          value: "checkbox group",
                          expression: "'checkbox group'"
                        }
                      ],
                      staticClass: "ml-4",
                      attrs: { stacked: "" },
                      model: {
                        value: _vm.preferences.selected,
                        callback: function($$v) {
                          _vm.$set(_vm.preferences, "selected", $$v)
                        },
                        expression: "preferences.selected"
                      }
                    },
                    _vm._l(_vm.options, function(option) {
                      return _c(
                        "b-form-checkbox",
                        {
                          key: option.text,
                          staticClass: "mb-3",
                          attrs: { value: option.value }
                        },
                        [_vm._v(" " + _vm._s(option.text) + " ")]
                      )
                    }),
                    1
                  ),
                  _vm.options.length === 0
                    ? _c("b-form-text", { staticClass: "h6" }, [
                        _vm._v("No " + _vm._s(_vm.type) + " were found.")
                      ])
                    : _vm.options.length > 0 &&
                      _vm.options.length !== _vm.allValues.length &&
                      _vm.filter.length < 3
                    ? _c(
                        "b-form-row",
                        { staticClass: "ml-4" },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "pt-0 pb-0",
                              attrs: {
                                size: "sm",
                                variant: "outline-secondary"
                              },
                              on: { click: _vm.loadAdditionalOptions }
                            },
                            [
                              _vm.isLoadingMore
                                ? _c("b-spinner", { attrs: { small: "" } })
                                : _c("b-icon-three-dots")
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }