import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import { AuthUtil } from '@infinity/shared/utils/auth'
import FileUpload from '@/views/FileUpload.vue'
import ResultsView from '@/views/Results.vue'
import Mapping from '@/views/Mapping.vue'
import Preferences from '@/views/Preferences.vue'
import UploadHistory from '@/views/UploadHistory.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Upload File',
    component: FileUpload
  },
  {
    path: '/mapping',
    name: 'Mapping',
    component: Mapping
  },
  {
    path: '/upload-history',
    name: 'Upload History',
    component: UploadHistory
  },
  {
    path: '/preferences',
    name: 'Preferences',
    component: Preferences
  },
  {
    path: '/:filename',
    name: 'Results View',
    component: ResultsView
  }
]

const router = new VueRouter({
  mode: 'history',
  base: AuthUtil.basePath || process.env.BASE_URL,
  routes
})

export default router

export function OnUploadRoute (routeName: string): boolean {
  const uploadRoutes = ['Upload File', 'Mapping', 'Results View']

  return uploadRoutes.includes(routeName)
}
