import Installation from '@infinity/shared/models/installation'
import { ApiFieldDefinition } from '@infinity/shared/models/base'
import { Api } from '@infinity/shared/helpers/api'

export default class InstallationConfig extends Installation {
  protected fieldDefinition: ApiFieldDefinition = {
    [Api.Hub]: {
      igrp: 'id',
      igrpName: 'name',
      tz: 'timeZone',
      voidUnmatchedCrmTriggers: 'voidTriggers'
    }
  }

  private voidTriggers = false

  setVoidTriggers (value: string | boolean) {
    if (typeof value === 'string') {
      this.voidTriggers = value === '1'

      return this
    }

    this.voidTriggers = value

    return this
  }

  getVoidTriggers () {
    return this.voidTriggers
  }
}
