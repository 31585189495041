var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-overlay", { attrs: { show: _vm.isLoading } }, [
    _c(
      "div",
      { staticClass: "rounded-lg bg-white d-flex p-3 align-items-center" },
      [
        _c("div", [
          _c("h5", [_vm._v("Only include matches in metrics")]),
          _c("p", [
            _vm._v(
              "If we can't match your data with a visitor, you can optionally choose to mark this data as 'void' - this will prevent unmatched data from being included in your metrics and will be hidden by default in the Hub."
            )
          ])
        ]),
        _c(
          "div",
          [
            _c("b-form-checkbox", {
              attrs: {
                size: "lg",
                checked: _vm.installationConfig.getVoidTriggers(),
                switch: ""
              },
              on: { change: _vm.doUpdateValue }
            })
          ],
          1
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }