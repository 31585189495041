import Model, { ApiFieldDefinition, apiToModelTransformer } from '@infinity/shared/models/base'
import { Api, JsonData } from '@infinity/shared/helpers/api'
import Batch from '@/models/Batch'
import { isArray } from 'lodash'

export default class BatchResponse extends Model {
  // TODO: Use Data Dictionary
  protected fieldDefinition: ApiFieldDefinition = {
    [Api.IM]: {
      totalBatchCount: 'totalBatchCount'
    }
  }

  private batches: Batch[] = []
  private totalBatchCount = 0

  setTotalBatchCount (totalBatchCount: number): BatchResponse {
    this.totalBatchCount = totalBatchCount

    return this
  }

  getTotalBatchCount (): number {
    return this.totalBatchCount
  }

  setBatches (batches: Batch[]): BatchResponse {
    this.batches = batches

    return this
  }

  getBatches (): Batch[] {
    return this.batches
  }

  fromApiTransformer (data: JsonData, api: Api): BatchResponse {
    apiToModelTransformer(this, data, this.fieldDefinition[api])

    if (isArray(data.batches)) {
      const batches: Batch[] = []

      for (const batch of data.batches) {
        batches.push(
          new Batch()
            .fromApiTransformer(batch as JsonData, api)
        )
      }

      this.setBatches(batches)
    }

    return this
  }

  toApiTransformer (): undefined {
    return undefined
  }
}
