import Model, { ApiFieldDefinition, apiToModelTransformer, modelToApiTransformer } from '@infinity/shared/models/base'
import { Api, JsonData } from '@infinity/shared/helpers/api'

export const MappingStatusDeleted = 410
export const MappingStatusComplete = 200

export default class Mapping extends Model {
  // TODO: Use Data Dictionary
  protected fieldDefinition: ApiFieldDefinition = {
    [Api.IM]: {
      batchId: 'batchId',
      installationId: 'installationId',
      updateDatetime: 'updateDatetime',
      jsonData: 'jsonData'
    }
  }

  private batchId = ''
  private installationId = ''
  private updateDatetime = ''
  private jsonData: JsonData = {}
  private config: JsonData = {}

  getBatchId (): string {
    return this.batchId
  }

  setBatchId (batchId: string): Mapping {
    this.batchId = batchId

    return this
  }

  getConfig (): JsonData {
    return this.config
  }

  setConfig (config: JsonData): Mapping {
    this.config = config

    return this
  }

  getInstallationId (): string {
    return this.installationId
  }

  setInstallationId (installationId: string): Mapping {
    this.installationId = installationId

    return this
  }

  getJsonData (): JsonData {
    return this.jsonData
  }

  setJsonData (jsonData: JsonData): Mapping {
    this.jsonData = jsonData

    return this
  }

  getUpdateDatetime (): string {
    return this.updateDatetime
  }

  setUpdateDatetime (updateDatetime: string): Mapping {
    this.updateDatetime = updateDatetime

    return this
  }

  fromApiTransformer (data: JsonData, api: Api): Mapping {
    apiToModelTransformer(this, data, this.fieldDefinition[api])

    return this
  }

  toApiTransformer (api: Api): JsonData {
    return modelToApiTransformer(this, this.fieldDefinition[api])
  }
}
