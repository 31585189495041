var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-container", { staticClass: "w-75 mt-4" }, [
    _c("div", { staticClass: "bg-white rounded-lg p-4" }, [
      _c("h5", [_c("b-icon-sliders"), _vm._v(" Set your Preferences")], 1),
      _vm.isLoading
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "test",
                  rawName: "v-test",
                  value: "loading",
                  expression: "'loading'"
                }
              ],
              staticClass:
                "bg-light w-100 p-5 rounded-lg d-flex flex-column align-items-center"
            },
            [
              _c("b-spinner"),
              _c("h4", { staticClass: "mt-3" }, [
                _vm._v("Loading your preferences...")
              ])
            ],
            1
          )
        : _vm.failedToLoad
        ? _c(
            "div",
            [
              _c(
                "b-row",
                {
                  staticClass: "ml-0 text-center",
                  attrs: { "align-v": "center" }
                },
                [
                  _c("p", { staticClass: "mb-0" }, [
                    _vm._v(
                      " We encountered a problem loading your preferences. Please try again. "
                    )
                  ]),
                  _c(
                    "b-button",
                    {
                      staticClass: "ml-2",
                      attrs: { variant: "light" },
                      on: { click: _vm.setupPreferences }
                    },
                    [_c("b-icon-arrow-clockwise")],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _c("div", [
            _c(
              "div",
              { staticClass: "mt-3 mb-4" },
              [
                _c(
                  "b-checkbox",
                  {
                    on: { change: _vm.unsetLookForward },
                    model: {
                      value: _vm.sameDayMatching,
                      callback: function($$v) {
                        _vm.sameDayMatching = $$v
                      },
                      expression: "sameDayMatching"
                    }
                  },
                  [
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "infinity-tooltip",
                            rawName: "v-infinity-tooltip",
                            value:
                              "Look back to the start of the day for a match and then look forward to the end of the same day.",
                            expression:
                              "'Look back to the start of the day for a match and then look forward to the end of the same day.'"
                          }
                        ]
                      },
                      [_vm._v(" Same-Day Matching ")]
                    )
                  ]
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "mb-4" },
              [
                _c(
                  "b-checkbox",
                  {
                    attrs: { disabled: _vm.sameDayMatching },
                    model: {
                      value: _vm.lookForward,
                      callback: function($$v) {
                        _vm.lookForward = $$v
                      },
                      expression: "lookForward"
                    }
                  },
                  [
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "infinity-tooltip",
                            rawName: "v-infinity-tooltip",
                            value:
                              "If no match is found within the set lookback window then look forward 24 hours.",
                            expression:
                              "'If no match is found within the set lookback window then look forward 24 hours.'"
                          }
                        ]
                      },
                      [_vm._v(" Look Forward ")]
                    )
                  ]
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "mb-4" },
              [
                _c(
                  "b-checkbox",
                  {
                    model: {
                      value: _vm.excludeDatetimeOnly,
                      callback: function($$v) {
                        _vm.excludeDatetimeOnly = $$v
                      },
                      expression: "excludeDatetimeOnly"
                    }
                  },
                  [
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "infinity-tooltip",
                            rawName: "v-infinity-tooltip",
                            value:
                              "If an entry has only a date or date/time to search on, then do not try to match it.",
                            expression:
                              "'If an entry has only a date or date/time to search on, then do not try to match it.'"
                          }
                        ]
                      },
                      [_vm._v(" Ignore Date/Time Only Entries ")]
                    )
                  ]
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "mb-4" },
              [
                _c("p", [
                  _vm._v(" How far back would you like to check for a match? ")
                ]),
                _c(
                  "b-form-select",
                  {
                    directives: [
                      {
                        name: "test",
                        rawName: "v-test",
                        value: "lookback hours select",
                        expression: "'lookback hours select'"
                      }
                    ],
                    attrs: {
                      value: _vm.lookbackInterval,
                      disabled: _vm.sameDayMatching
                    },
                    on: { change: _vm.updateLookback }
                  },
                  _vm._l(_vm.lookbackIntervalOptions, function(
                    lookbackInterval
                  ) {
                    return _c(
                      "b-form-select-option",
                      {
                        directives: [
                          {
                            name: "test",
                            rawName: "v-test",
                            value: "lookback hours " + lookbackInterval.text,
                            expression:
                              "`lookback hours ${lookbackInterval.text}`"
                          }
                        ],
                        key: lookbackInterval.text,
                        attrs: { value: lookbackInterval }
                      },
                      [_vm._v(" " + _vm._s(lookbackInterval.text) + " ")]
                    )
                  }),
                  1
                )
              ],
              1
            ),
            _vm.lookbackHours === 0
              ? _c("div", { staticClass: "mb-4" }, [
                  _c("p", [
                    _vm._v(
                      " How many minutes and seconds do you want to look back for? "
                    )
                  ]),
                  _c("div", { staticClass: "d-flex" }, [
                    _c(
                      "div",
                      { staticClass: "mr-3" },
                      [
                        _c("label", { attrs: { for: "lookbackSecondsMins" } }, [
                          _vm._v("Minutes")
                        ]),
                        _c("b-form-spinbutton", {
                          directives: [
                            {
                              name: "test",
                              rawName: "v-test",
                              value: "lookback seconds minutes",
                              expression: "'lookback seconds minutes'"
                            }
                          ],
                          staticClass: "ml-3",
                          attrs: {
                            id: "lookbackSecondsMins",
                            max: "60",
                            min: "0",
                            inline: ""
                          },
                          model: {
                            value: _vm.lookbackSecondsInputMins,
                            callback: function($$v) {
                              _vm.lookbackSecondsInputMins = $$v
                            },
                            expression: "lookbackSecondsInputMins"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("label", { attrs: { for: "lookbackSecondsSecs" } }, [
                          _vm._v("Seconds")
                        ]),
                        _c("b-form-spinbutton", {
                          directives: [
                            {
                              name: "test",
                              rawName: "v-test",
                              value: "lookback seconds seconds",
                              expression: "'lookback seconds seconds'"
                            }
                          ],
                          staticClass: "ml-3",
                          attrs: {
                            id: "lookbackSecondsSecs",
                            max: _vm.lookbackSecondsInputMins === 60 ? 0 : 59,
                            min: "0",
                            inline: ""
                          },
                          model: {
                            value: _vm.lookbackSecondsInputSecs,
                            callback: function($$v) {
                              _vm.lookbackSecondsInputSecs = $$v
                            },
                            expression: "lookbackSecondsInputSecs"
                          }
                        })
                      ],
                      1
                    )
                  ])
                ])
              : _vm._e(),
            _c(
              "div",
              { staticClass: "mb-4" },
              [
                _c("p", [
                  _vm._v(
                    " Which tracking pools / fixed numbers do you want to include in matching? "
                  )
                ]),
                _c(
                  "b-form-select",
                  {
                    attrs: { value: _vm.numberScope },
                    on: { change: _vm.updateNumberScope }
                  },
                  _vm._l(_vm.NumberScope, function(numberScope) {
                    return _c(
                      "b-form-select-option",
                      { key: numberScope, attrs: { value: numberScope } },
                      [_vm._v(_vm._s(numberScope))]
                    )
                  }),
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              [
                _c("p", [
                  _vm._v(" Email address for SFTP upload notifications ")
                ]),
                _c("b-form-tags", {
                  directives: [
                    {
                      name: "test",
                      rawName: "v-test",
                      value: "notification emails",
                      expression: "'notification emails'"
                    }
                  ],
                  attrs: {
                    value: _vm.notificationEmails,
                    "tag-validator": _vm.validateEmail,
                    "input-type": "email",
                    "invalid-tag-text": "invalid email address",
                    "duplicate-tag-text": "duplicate email addresses",
                    placeholder: "Add email address..."
                  },
                  on: { input: _vm.updateNotificationEmails }
                })
              ],
              1
            )
          ])
    ]),
    !(_vm.isLoading || _vm.failedToLoad) &&
    _vm.numberScope === _vm.NumberScope.SPECIFIC
      ? _c("div", [
          _c(
            "div",
            {
              directives: [
                {
                  name: "test",
                  rawName: "v-test",
                  value: "include tracking pools",
                  expression: "'include tracking pools'"
                }
              ],
              staticClass: "bg-white rounded-lg p-4 mt-4"
            },
            [
              _c(
                "h5",
                [_c("b-icon-collection-fill"), _vm._v(" Tracking Pools")],
                1
              ),
              _c("p", [
                _vm._v(
                  " Select tracking pools to include " +
                    _vm._s(
                      _vm.trackingPoolConfig.excludeFixedNumbers
                        ? "only pool"
                        : "both pool and fixed"
                    ) +
                    " numbers in matching. "
                )
              ]),
              _c("CheckboxGroup", {
                attrs: {
                  type: _vm.CheckboxGroupType.Pools,
                  options: _vm.trackingPoolOptions,
                  preferences: _vm.trackingPoolConfig,
                  allValues: _vm.trackingPoolIds
                }
              })
            ],
            1
          ),
          _vm.trackingPoolConfig.excludeFixedNumbers
            ? _c(
                "div",
                {
                  directives: [
                    {
                      name: "test",
                      rawName: "v-test",
                      value: "include fixed numbers",
                      expression: "'include fixed numbers'"
                    }
                  ],
                  staticClass: "bg-white rounded-lg p-4 mt-4"
                },
                [
                  _c(
                    "h5",
                    [
                      _c("b-icon-telephone-fill"),
                      _vm._v(" Include Specific Fixed Numbers")
                    ],
                    1
                  ),
                  _c("p", [
                    _vm._v(
                      " Select specific fixed numbers from your tracking pools to include in matching. "
                    )
                  ]),
                  _c("CheckboxGroup", {
                    attrs: {
                      type: _vm.CheckboxGroupType.FixedNumbers,
                      options: _vm.fixedNumberOptions,
                      preferences: _vm.fixedNumberConfig,
                      allValues: _vm.fixedNumberIds
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ])
      : _vm._e(),
    _c(
      "div",
      { staticClass: "mt-3 d-flex" },
      [
        _c(
          "b-button",
          {
            directives: [
              {
                name: "test",
                rawName: "v-test",
                value: "save button",
                expression: "'save button'"
              }
            ],
            staticClass: "ml-auto",
            attrs: {
              disabled: _vm.isLoading || _vm.failedToLoad,
              variant: "success"
            },
            on: { click: _vm.doSave }
          },
          [
            _vm.isLoading
              ? _c("b-spinner", { attrs: { small: "" } })
              : _vm._e(),
            _vm._v(" Save")
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }