






















































































import { Component, Vue } from 'vue-property-decorator'
import Result from '@/components/Result.vue'
import {
  AvatarPlugin, BIcon, BIconCalendar2CheckFill, BIconCloudUpload, BIconCreditCard, BIconInfoCircleFill,
  BIconTelephoneFill, BIconCashStack,
  FormFilePlugin,
  FormGroupPlugin,
  FormPlugin,
  SpinnerPlugin, ToastPlugin
} from 'bootstrap-vue'
import VoidTriggers from '@/components/VoidTriggers.vue'
import { get } from 'lodash'
import { FileError } from '@/store'

Vue.use(FormPlugin)
Vue.use(SpinnerPlugin)
Vue.use(FormFilePlugin)
Vue.use(FormGroupPlugin)
Vue.use(AvatarPlugin)
Vue.use(ToastPlugin)

@Component({
  components: {
    VoidTriggers,
    Result,
    BIconTelephoneFill,
    BIconCloudUpload,
    BIconCalendar2CheckFill,
    BIcon,
    BIconCreditCard,
    BIconInfoCircleFill,
    BIconCashStack
  }
})
export default class FileUpload extends Vue {
  private file = null
  private maxValidationRows = 20
  private isLoading = false

  created () {
    this.$mixpanelService.startTimedEvent('settings.smartMatch.view', {}, true)
  }

  appDeactivated () {
    this.$mixpanelService.trackTimedEvent('settings.smartMatch.view')
  }

  get fileInputState () {
    if (this.$store.state.fileError === FileError.None) {
      return null
    }

    return false
  }

  get fileMessage () {
    if (this.$store.state.fileError === FileError.InvalidRowLength) {
      return 'Some of your CSV rows have an incorrect length according to the number of headers provided. Please check the file and try again.'
    }

    if (this.$store.state.fileError === FileError.Unknown) {
      return 'There was a problem processing your CSV. Please check the file and try again.'
    }

    if (this.$store.state.fileError === FileError.InvalidHeaders) {
      return `The CSV you selected contained the following invalid headers: ${this.$store.state.invalidHeaders.join(', ')}.`
    }
  }

  get installationId () {
    return this.$infinityAuth.getInstallation().getId()
  }

  get results () {
    return this.$store.state.fileValidation
  }

  get csvFile () {
    return this.$store.state.csvFile
  }

  async processFile () {
    if (this.file !== null) {
      await this.$store.dispatch('reset')

      this.isLoading = true

      try {
        await this.$store.dispatch('prepareForUpload', { file: this.file, maxRows: this.maxValidationRows })

        if (this.$store.state.fileError !== FileError.None) {
          this.isLoading = false

          return
        }

        await this.$router.push('/mapping')
      } catch (e) {
        const message = get(e, 'response.data.error', 'An unknown error occurred, please try again')

        this.file = null
        this.$bvToast.toast(message, {
          title: 'Error',
          variant: 'danger',
          autoHideDelay: 5000
        })
      }
    }

    this.isLoading = false
  }

  doReset () {
    this.isLoading = false

    this.$store.dispatch('reset')
  }
}
