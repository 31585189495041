import Model, { ApiFieldDefinition, apiToModelTransformer, modelToApiTransformer } from '@infinity/shared/models/base'
import { Api, JsonData } from '@infinity/shared/helpers/api'

export enum NumberScope {
  ALL_NUMBERS = 'All Tracking Pools and Fixed Numbers',
  ALL_POOLS = 'All Tracking Pools (excluding fixed numbers)',
  ALL_FIXED = 'All Fixed Numbers',
  SPECIFIC = 'Specific Tracking Pools and Fixed Numbers'
}

export interface Lookback {
  text: string;
  value: number;
}

export const LookbackIntervals = [
  { text: 'Less than One Hour', value: 0 },
  { text: 'One Hour', value: 1 },
  { text: 'Three Hours', value: 3 },
  { text: 'Six Hours', value: 6 },
  { text: 'Twelve Hours', value: 12 },
  { text: 'One Day', value: 24 },
  { text: 'Two Days', value: 48 },
  { text: 'Three Days', value: 72 },
  { text: 'Four Days', value: 96 },
  { text: 'Five Days', value: 120 },
  { text: 'Six Days', value: 144 },
  { text: 'One Week', value: 168 },
  { text: 'Two Weeks', value: 336 },
  { text: 'Three Weeks', value: 504 },
  { text: 'One Month (Thirty Days)', value: 720 },
  { text: 'Two Months (Sixty Days)', value: 1440 },
  { text: 'Three Months (Ninety Days)', value: 2160 } as Lookback
]

export class PreferenceConfiguration extends Model {
  // TODO: Use Data Dictionary
  protected fieldDefinition: ApiFieldDefinition = {
    [Api.IM]: {
      numberScope: 'numberScope',
      trackingPoolIds: 'trackingPoolIds',
      fixedNumberIds: 'fixedNumberIds',
      excludeFixedNumbers: 'excludeFixedNumbers',
      sameDayMatching: 'sameDayMatching',
      lookForward: 'lookForward',
      lookbackHours: 'lookbackHours',
      lookbackSeconds: 'lookbackSeconds',
      excludeDatetimeOnly: 'excludeDatetimeOnly',
      notificationEmails: 'notificationEmails'
    }
  }

  private numberScope: string = Object.keys(NumberScope)[0]
  private trackingPoolIds: number[] = []
  private fixedNumberIds: number[] = []
  private excludeFixedNumbers = false
  private sameDayMatching = false
  private lookForward = false
  private lookbackHours = 720
  private lookbackSeconds = 0;
  private excludeDatetimeOnly = false
  private notificationEmails = ''

  getNumberScope (): string {
    return this.numberScope
  }

  setNumberScope (numberScope: string): PreferenceConfiguration {
    this.numberScope = numberScope

    return this
  }

  getTrackingPoolIds (): number[] {
    return this.trackingPoolIds
  }

  setTrackingPoolIds (trackingPoolIds: number[]): PreferenceConfiguration {
    this.trackingPoolIds = trackingPoolIds

    return this
  }

  getFixedNumberIds (): number[] {
    return this.fixedNumberIds
  }

  setFixedNumberIds (fixedNumberIds: number[]): PreferenceConfiguration {
    this.fixedNumberIds = fixedNumberIds

    return this
  }

  getExcludeFixedNumbers (): boolean {
    return this.excludeFixedNumbers
  }

  setExcludeFixedNumbers (excludeFixedNumbers: boolean): PreferenceConfiguration {
    this.excludeFixedNumbers = excludeFixedNumbers

    return this
  }

  getSameDayMatching (): boolean {
    return this.sameDayMatching
  }

  setSameDayMatching (sameDayMatching: boolean): PreferenceConfiguration {
    this.sameDayMatching = sameDayMatching

    return this
  }

  getLookForward (): boolean {
    return this.lookForward
  }

  setLookForward (lookForward: boolean): PreferenceConfiguration {
    this.lookForward = lookForward

    return this
  }

  getLookbackHours (): number {
    return this.lookbackHours
  }

  setLookbackHours (lookbackHours: number): PreferenceConfiguration {
    this.lookbackHours = lookbackHours

    return this
  }

  getLookbackSeconds (): number {
    return this.lookbackSeconds
  }

  setLookbackSeconds (lookbackSeconds: number): PreferenceConfiguration {
    this.lookbackSeconds = lookbackSeconds

    return this
  }

  getExcludeDatetimeOnly (): boolean {
    return this.excludeDatetimeOnly
  }

  setExcludeDatetimeOnly (excludeDatetimeOnly: boolean): PreferenceConfiguration {
    this.excludeDatetimeOnly = excludeDatetimeOnly

    return this
  }

  getNotificationEmails (): string {
    return this.notificationEmails
  }

  setNotificationEmails (notificationEmails: string): PreferenceConfiguration {
    this.notificationEmails = notificationEmails

    return this
  }

  fromApiTransformer (data: JsonData, api: Api): PreferenceConfiguration {
    apiToModelTransformer(this, data, this.fieldDefinition[api])

    if (this.lookbackSeconds > 0) {
      this.lookbackHours = 0
    }

    return this
  }

  toApiTransformer (api: Api): JsonData {
    return modelToApiTransformer(this, this.fieldDefinition[api])
  }
}

export default class Preference extends Model {
  // TODO: Use Data Dictionary
  protected fieldDefinition: ApiFieldDefinition = {
    [Api.IM]: {
      preferenceId: 'preferenceId',
      installationId: 'installationId',
      updateDatetime: 'updateDatetime',
      configuration: 'configuration'
    }
  }

  private preferenceId = 0
  private installationId = 0
  private updateDatetime = ''
  private configuration = new PreferenceConfiguration()

  getPreferenceId (): number {
    return this.preferenceId
  }

  setPreferenceId (preferenceId: number): Preference {
    this.preferenceId = preferenceId

    return this
  }

  getInstallationId (): number {
    return this.installationId
  }

  setInstallationId (installationId: number): Preference {
    this.installationId = installationId

    return this
  }

  getUpdateDatetime (): string {
    return this.updateDatetime
  }

  setUpdateDatetime (updateDatetime: string): Preference {
    this.updateDatetime = updateDatetime

    return this
  }

  getConfiguration (): PreferenceConfiguration {
    return this.configuration
  }

  setConfiguration (configuration: JsonData): Preference {
    this.configuration = new PreferenceConfiguration().fromApiTransformer(configuration, Api.IM)

    return this
  }

  fromApiTransformer (data: JsonData, api: Api): Preference {
    apiToModelTransformer(this, data, this.fieldDefinition[api])

    return this
  }

  toApiTransformer (api: Api): JsonData {
    return modelToApiTransformer(this, this.fieldDefinition[api])
  }
}

export type UpdatePreferenceRequest = {
  installationId: number;
  numberScope: NumberScope;
  trackingPoolIds: number[];
  fixedNumberIds: number[];
  excludeFixedNumbers: boolean;
  sameDayMatching: boolean;
  lookForward: boolean;
  lookbackHours: number;
  lookbackSeconds: number;
  excludeDatetimeOnly: boolean;
  notificationEmails: string[];
}
