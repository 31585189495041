import Repository from '@infinity/shared/repos/base'
import { Api } from '@infinity/shared/helpers/api'
import Preference, { UpdatePreferenceRequest } from '@/models/Preference'
import axios, { AxiosResponse } from 'axios'

export default class PreferencesRepo extends Repository {
  constructor () {
    super(Api.IM, 'preferences', Preference, 'data')
  }

  async getPreferences (installationId: string) {
    let response: AxiosResponse
    try {
      response = await axios.get(`${this.api}/${this.apiRoute}`, {
        params: { installationId },
        ...this.requestConfig
      })
    } catch (e) {
      if (e.message === ('Request failed with status code 404')) {
        return new this.Model()
      }

      throw e
    }

    const data = response.data.data
    if (!data) {
      return new this.Model()
    }

    return new this.Model().fromApiTransformer(data, this.api)
  }

  async updatePreferences (installationId: string, requestData: UpdatePreferenceRequest) {
    const response = await axios.post(`${this.api}/${this.apiRoute}`, requestData, {
      ...this.requestConfig,
      params: {
        installationId: installationId
      }
    })

    const data = response.data.data
    if (!data) {
      return null
    }

    return new this.Model().fromApiTransformer(data, this.api)
  }

  get requestConfig (): { headers: { [k: string]: string | null } } | null {
    const headers = super.requestConfig
    if (!headers) {
      return null
    }

    // Remove as this violates IM CORS
    delete headers.headers['x-auth-token']

    return headers
  }
}
