var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.results
    ? _c(
        "div",
        [
          _c("Result", {
            attrs: { results: _vm.results, csvFile: _vm.csvFile }
          })
        ],
        1
      )
    : _c(
        "div",
        { staticClass: "mt-4" },
        [
          _c(
            "b-container",
            { staticClass: "w-75" },
            [
              _c(
                "div",
                { staticClass: "bg-white p-4 rounded-lg bordered" },
                [
                  _c(
                    "h5",
                    [
                      _c("b-icon-info-circle-fill", {
                        attrs: { variant: "primary" }
                      }),
                      _vm._v(" Uploading a CSV file")
                    ],
                    1
                  ),
                  _c("p", [
                    _vm._v(
                      "The file should contain a column with Caller ID, Date/Time details and Conversion Data. Conversion Data can be your call outcomes, transaction values or CRM events. The more detail you can share, the better. "
                    )
                  ]),
                  _c(
                    "b-row",
                    {
                      staticClass: "text-center mt-5",
                      attrs: { "align-v": "center" }
                    },
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "3" } },
                        [
                          _c(
                            "b-avatar",
                            { attrs: { variant: "light", size: "50" } },
                            [
                              _c("b-icon-telephone-fill", {
                                attrs: { "font-scale": "2" }
                              })
                            ],
                            1
                          ),
                          _c("p", { staticClass: "h5 mt-2" }, [
                            _vm._v("Caller ID")
                          ])
                        ],
                        1
                      ),
                      _c("b-col", [
                        _c("p", { staticClass: "h3 mt-2" }, [
                          _c("b", [_vm._v("+")])
                        ])
                      ]),
                      _c(
                        "b-col",
                        { attrs: { cols: "3" } },
                        [
                          _c(
                            "b-avatar",
                            { attrs: { variant: "light", size: "50" } },
                            [
                              _c("b-icon-calendar2-check-fill", {
                                attrs: { "font-scale": "2" }
                              })
                            ],
                            1
                          ),
                          _c("p", { staticClass: "h5 mt-2" }, [
                            _vm._v("Date & Time")
                          ])
                        ],
                        1
                      ),
                      _c("b-col", [
                        _c("p", { staticClass: "h3 mt-2" }, [
                          _c("b", [_vm._v("+")])
                        ])
                      ]),
                      _c(
                        "b-col",
                        { attrs: { cols: "3" } },
                        [
                          _c(
                            "b-avatar",
                            { attrs: { variant: "light", size: "50" } },
                            [
                              _c("b-icon-cash-stack", {
                                attrs: { "font-scale": "2" }
                              })
                            ],
                            1
                          ),
                          _c("p", { staticClass: "h5 mt-2" }, [
                            _vm._v("Transaction or Outcome")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("void-triggers", { staticClass: "mt-3" })
            ],
            1
          ),
          _c(
            "b-container",
            { staticClass: "w-50 mt-4" },
            [
              _c(
                "b-form-group",
                {
                  attrs: { state: _vm.fileInputState },
                  scopedSlots: _vm._u([
                    {
                      key: "invalid-feedback",
                      fn: function() {
                        return [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex file-error w-100 justify-content-center"
                            },
                            [
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "test",
                                      rawName: "v-test",
                                      value: "file error",
                                      expression: "'file error'"
                                    }
                                  ],
                                  staticClass:
                                    "bg-danger p-2 text-white rounded"
                                },
                                [_vm._v(" " + _vm._s(_vm.fileMessage) + " ")]
                              )
                            ]
                          )
                        ]
                      },
                      proxy: true
                    }
                  ])
                },
                [
                  _c(
                    "b-form-file",
                    {
                      ref: "file-input",
                      staticClass: "file-input",
                      attrs: {
                        accept: ".csv",
                        id: "imfile",
                        disabled: _vm.isLoading,
                        state: _vm.fileInputState
                      },
                      on: { input: _vm.processFile },
                      scopedSlots: _vm._u([
                        {
                          key: "file-name",
                          fn: function(scope) {
                            return [
                              _c(
                                "div",
                                { staticClass: "uploadPlaceholder" },
                                [
                                  _c(
                                    "h2",
                                    [
                                      _c("b-icon-cloud-upload", {
                                        attrs: { variant: "primary" }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._l(scope.names, function(name) {
                                    return _c("strong", { key: name }, [
                                      _vm._v(_vm._s(name))
                                    ])
                                  }),
                                  _c("br")
                                ],
                                2
                              ),
                              _vm.isLoading
                                ? _c(
                                    "div",
                                    { staticClass: "loading" },
                                    [_c("b-spinner")],
                                    1
                                  )
                                : _vm._e()
                            ]
                          }
                        }
                      ]),
                      model: {
                        value: _vm.file,
                        callback: function($$v) {
                          _vm.file = $$v
                        },
                        expression: "file"
                      }
                    },
                    [
                      _c("template", { slot: "placeholder" }, [
                        _c("div", { staticClass: "uploadPlaceholder" }, [
                          _c("h2", [_c("b-icon-cloud-upload")], 1),
                          _c("strong", [
                            _vm._v("Click here to select your .csv file")
                          ]),
                          _vm._v(" Alternatively, drag and drop a file here ")
                        ])
                      ]),
                      _c("template", { slot: "drop-placeholder" }, [
                        _c("div", { staticClass: "uploadPlaceholder" }, [
                          _c(
                            "h2",
                            [
                              _c("b-icon-cloud-upload", {
                                attrs: { variant: "primary" }
                              })
                            ],
                            1
                          ),
                          _c("strong", [_vm._v("Drop your .csv file here")]),
                          _c("br")
                        ])
                      ])
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }