var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    [
      _c(
        "b-nav",
        { staticClass: "w-100", attrs: { justified: "", pills: "" } },
        [
          _c("b-nav-item", { attrs: { to: "/" } }, [
            _c(
              "h4",
              [
                _c("b-icon-cloud-upload-fill", {
                  attrs: {
                    variant: _vm.onUploadRoute(_vm.$route.name)
                      ? "primary"
                      : null
                  }
                }),
                _c("br"),
                _vm._v(" Upload ")
              ],
              1
            )
          ]),
          _c("b-nav-item", { attrs: { to: "/upload-history" } }, [
            _c(
              "h4",
              [
                _c("b-icon-clock-history", {
                  attrs: {
                    variant:
                      _vm.$route.name === "Upload History" ? "primary" : null
                  }
                }),
                _c("br"),
                _vm._v(" Upload History ")
              ],
              1
            )
          ]),
          _c("b-nav-item", { attrs: { to: "/preferences" } }, [
            _c(
              "h4",
              [
                _c("b-icon-tools", {
                  attrs: {
                    variant:
                      _vm.$route.name === "Preferences" ? "primary" : null
                  }
                }),
                _c("br"),
                _vm._v(" Preferences ")
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }