import Repository, { DefaultLimit } from '@infinity/shared/repos/base'
import { Api } from '@infinity/shared/helpers/api'
import { Filter, FilterCollection, FilterOperand } from '@infinity/shared/helpers/filter'
import FixedNumber from '@/models/FixedNumber'
import axios from 'axios'

export default class FixedNumberRepo extends Repository {
  constructor (installationId: number) {
    super(Api.Hub, `config/igrps/${installationId}/dgrps/all/numbers/fixed`, FixedNumber, 'numbers')
  }

  async getFixedNumberIds (): Promise<number[]> {
    const filters = new FilterCollection(new Filter('ch', FilterOperand.NotEquals, 0))

    const response = await axios.get(`${this.api}/${this.apiRoute}`, {
      params: { filters: filters.toApi() },
      ...this.requestConfig
    })

    const data = []
    if (response && response.data) {
      const items = response.data.numbers

      if (items) {
        for (const item of items) {
          data.push(parseInt(item.phoneId))
        }
      }
    }

    return data
  }

  async getFixedNumbers (filter: string | null, offset: number): Promise<FixedNumber[] | null> {
    const filters = new FilterCollection(new Filter('ch', FilterOperand.NotEquals, 0))

    if (filter && filter !== '') {
      filters.addFilter(new Filter('usageFilter', FilterOperand.IncludesI, filter))
    }

    const model = await this.fetch(filters, DefaultLimit, offset)

    if (Array.isArray(model)) {
      model.forEach((model) => {
        if (!(model instanceof FixedNumber)) {
          return null
        }
      })

      return model as FixedNumber[]
    }

    return null
  }
}
