












































import { Component, Vue, Prop } from 'vue-property-decorator'
import FileValidation from '@/models/FileValidation'
import CsvFile from '@/models/csvFile'
import {
  BIconArrowClockwise,
  BIconExclamationDiamondFill,
  BIconExclamationTriangleFill,
  BIconCheckCircleFill,
  ButtonPlugin,
  TablePlugin,
  TableSimplePlugin
} from 'bootstrap-vue'

Vue.use(TablePlugin)
Vue.use(TableSimplePlugin)
Vue.use(ButtonPlugin)

type ResultRow = {
  value?: string | null;
  variant?: string | null;
  error?: string | null;
}

@Component({
  components: {
    BIconExclamationDiamondFill,
    BIconExclamationTriangleFill,
    BIconArrowClockwise,
    BIconCheckCircleFill
  }
})
export default class Result extends Vue {
  @Prop({ default: null }) results?: FileValidation | null
  @Prop({ default: null }) csvFile?: CsvFile | null

  get isValid (): boolean {
    const { results } = this

    if (results instanceof FileValidation) {
      return typeof results.getUrl() === 'string'
    }

    return false
  }

  doReset () {
    this.$store.dispatch('reset')
  }

  /**
   * Maps a row of csv data to combine with any error messages as well as any formatting to apply to that cell
  **/
  getRowDataWithErrors (index: number, row: Array<string>): Array<ResultRow> {
    const results = this.results instanceof FileValidation ? this.results.getValidationResults() : []
    const headers = this.csvFile instanceof CsvFile ? this.csvFile.getHeaders() : []

    // add an extra column to show the row number
    return [`${index}`, ...row].map(
      (value, columnIndex) => {
        const data: ResultRow = {
          value: columnIndex === 0 ? `${index < 0 ? '' : index + 1}` : value,
          variant: index < 0 || columnIndex === 0 ? 'secondary' : null
        }
        if (Array.isArray(results[index])) {
          const errors = results[index].find(
            ({ field }) => {
              return field === (columnIndex === 0 ? '' : headers[columnIndex - 1])
            }
          )

          if (errors) {
            data.error = errors.message
          }
        }

        return data
      }
    )
  }

  get headers (): Array<ResultRow> {
    const headers = this.csvFile instanceof CsvFile ? this.csvFile.getHeaders() : []

    return this.getRowDataWithErrors(-1, headers)
  }

  get rows (): Array<Array<ResultRow>> {
    const rows = this.csvFile instanceof CsvFile ? this.csvFile.getRows() : []

    return rows.map(
      (row, rowIndex) => {
        return this.getRowDataWithErrors(rowIndex, row)
      }
    )
  }
}
