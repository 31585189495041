import Vue from 'vue'
import Vuex, { Commit } from 'vuex'
import FileValidation from '@/models/FileValidation'
import CsvFile from '@/models/csvFile'
import Papa from 'papaparse'
import { get, omitBy, isUndefined, map } from 'lodash'
import ValidationRepo from '@/repos/ValidationRepo'
import { Api } from '@infinity/shared/helpers/api'
import S3Repository from '@/repos/s3Repo'
import axios from 'axios'
import { AuthUtil } from '@infinity/shared/utils/auth'
import ValidationResults from '@/models/ValidationResults'
import ValidationResultsRepo from '@/repos/ValidationResultsRepo'
import Dexie from 'dexie'
import BatchRepo from '@/repos/BatchRepo'
import MappingsRepo from '@/repos/MappingsRepo'
import { FormatConfig } from '@/views/Mapping.vue'
import PreferencesRepo from '@/repos/PreferencesRepo'
import Preference, { UpdatePreferenceRequest } from '@/models/Preference'
import TrackingPoolRepo from '@/repos/TrackingPoolRepo'
import TrackingPool from '@/models/TrackingPool'
import FixedNumberRepo from '@/repos/FixedNumberRepo'
import FixedNumber from '@/models/FixedNumber'
import ResultsRepo from '@/repos/ResultsRepo'
import Results from '@/models/Results'
import { BToast } from 'bootstrap-vue'

Vue.use(Vuex)

export enum IntelligentMatchField {
  Action = 'act',
  VisitorId = 'vid',
  CallRef = 'callRef',
  Title = 't',
  Value = 'value',
  EventDatetime = 'dt',
  TransactionValue = 'txv',
  TransactionCurrency = 'txc',
  TransactionRef = 'txr',
  TransactionListPrice = 'transactionListPrice',
  TransactionProduct = 'transactionProduct',
  TransactionProductCategory = 'transactionProductCategory',
  TransactionProfit = 'transactionProfit',
  TransactionCustomerRef = 'transactionCustomerRef',
  MatchMode = 'matchMode',
  CallStartDatetime = 'callStartDt',
  SrcPhoneNumber = 'srcPhoneNumber',
  Placeholder = 'placeholder',
  returnDeleted = 'returnDeleted',
  AgentRef = 'operatorRef'
}

export const RequiredFields = [
  IntelligentMatchField.EventDatetime
]

export const AllowedMultipleFields = [
  IntelligentMatchField.SrcPhoneNumber
]

export enum IntelligentMatchStatus { None, Failed, Success }
export enum FileError { None, InvalidRowLength, InvalidHeaders, Unknown }

export class IntelligentMatchState {
  status = IntelligentMatchStatus.None
  fileError = FileError.None
  uploadUrl = ''
  uploadRoutesOnly = false
  filename = ''
  installationId = ''
  mappingId = ''
  hasMappingChanges = false
  csvMatchesSavedMapping = true
  savedMappingFound = false
  uploadedFile: File | null = null
  mappedFile: File | null = null
  csvFile: CsvFile | null = null
  fileValidation: FileValidation | null = null
  validationResults: ValidationResults | null = null
  invalidHeaders: string[] = []
  database: Dexie | null = null
  mappingConfig: { [k: string]: string } = {}
  batchFile: Results | null = null
  formatConfig: FormatConfig = {
    datetimeFormat: null,
    callStartDatetimeFormat: null,
    timezone: null,
    phoneNumberCountryCode: null
  }

  preferences: Preference | null = null
  trackingPoolIds: number[] = []
  trackingPools: TrackingPool[] | null = null
  fixedNumberIds: number[] = []
  fixedNumbers: FixedNumber[] | null = null
  returnDeleted: false | boolean = false
}

export default new Vuex.Store({
  state: new IntelligentMatchState(),
  mutations: {
    setStatus (state: IntelligentMatchState, status: IntelligentMatchStatus) {
      state.status = status
    },
    setFileError (state: IntelligentMatchState, error: FileError) {
      state.fileError = error
    },
    setUploadUrl (state: IntelligentMatchState, uploadUrl: string) {
      state.uploadUrl = uploadUrl
    },
    setUploadRoutesOnly (state: IntelligentMatchState, uploadRoutesOnly: boolean) {
      state.uploadRoutesOnly = uploadRoutesOnly
    },
    setFilename (state: IntelligentMatchState, filename: string) {
      state.filename = filename
    },
    setInstallationId (state: IntelligentMatchState, installationId: string) {
      state.installationId = installationId
    },
    setUploadedFile (state: IntelligentMatchState, file: File | null) {
      state.uploadedFile = file
    },
    setCsvFile (state: IntelligentMatchState, csvFile: CsvFile | null) {
      state.csvFile = csvFile
    },
    setFileValidation (state: IntelligentMatchState, fileValidation: FileValidation | null) {
      state.fileValidation = fileValidation
    },
    setValidationResults (state: IntelligentMatchState, validationResults: ValidationResults | null) {
      state.validationResults = validationResults
    },
    addInvalidHeader (state: IntelligentMatchState, header: string) {
      state.invalidHeaders.push(header)
    },
    resetInvalidHeaders (state: IntelligentMatchState) {
      state.invalidHeaders = []
    },
    setMappedFile (state: IntelligentMatchState, file: File) {
      state.mappedFile = file
    },
    createCrmDb (state: IntelligentMatchState) {
      state.database = new Dexie('crmDb')
    },
    async deleteCrmDb (state: IntelligentMatchState) {
      if (state.database) {
        // @ts-ignore
        await state.database.crmData.clear()
      }
    },
    addMappedField (state: IntelligentMatchState, field: string) {
      let value = ''

      if (state.csvFile) {
        for (const header of state.csvFile.getHeaders()) {
          if (header === field) {
            value = header
          }
        }
      }

      Vue.set(state.mappingConfig, field, value)
    },
    setMappingConfig (state: IntelligentMatchState, config: { [k: string]: string }) {
      state.mappingConfig = config
    },
    resetMappingConfig (state: IntelligentMatchState) {
      state.mappingConfig = {}
    },
    setMappingId (state: IntelligentMatchState, id: string) {
      state.mappingId = id
    },
    setHasMappingChanges (state: IntelligentMatchState, hasChanges: boolean) {
      state.hasMappingChanges = hasChanges
    },
    setCsvMatchesSavedMapping (state: IntelligentMatchState, matches: boolean) {
      state.csvMatchesSavedMapping = matches
    },
    setSavedMappingIsFound (state: IntelligentMatchState, found: boolean) {
      state.savedMappingFound = found
    },
    setBatchFile (state: IntelligentMatchState, batchFile: Results | null) {
      state.batchFile = batchFile
    },
    setFormatConfig (state: IntelligentMatchState, formatConfig: FormatConfig) {
      state.formatConfig = formatConfig
    },
    setPreferences (state: IntelligentMatchState, preferences: Preference) {
      state.preferences = preferences
    },
    setTrackingPoolIds (state: IntelligentMatchState, ids: number[]) {
      state.trackingPoolIds = ids
    },
    setTrackingPools (state: IntelligentMatchState, trackingPools: Array<TrackingPool>) {
      state.trackingPools = trackingPools
    },
    setFixedNumberIds (state: IntelligentMatchState, ids: number[]) {
      state.fixedNumberIds = ids
    },
    setFixedNumbers (state: IntelligentMatchState, fixedNumbers: Array<FixedNumber>) {
      state.fixedNumbers = fixedNumbers
    },
    addTrackingPools (state: IntelligentMatchState, trackingPools: Array<TrackingPool>) {
      if (!state.trackingPools) {
        return
      }

      for (const trackingPool of trackingPools) {
        state.trackingPools.push(trackingPool)
      }
    },
    addFixedNumbers (state: IntelligentMatchState, fixedNumbers: Array<FixedNumber>) {
      if (!state.fixedNumbers) {
        return
      }

      for (const fixedNumber of fixedNumbers) {
        state.fixedNumbers.push(fixedNumber)
      }
    }
  },
  actions: {
    // Sets the file in storage and prepares for pre-validation
    async prepareForUpload (
      { state, commit }: { state: IntelligentMatchState ; commit: Commit },
      { file, maxRows }: { file: File; maxRows: number }
    ) {
      commit('setFileError', FileError.None)
      commit('setUploadedFile', file)

      const csvFile = new CsvFile()
        .setInstallationId(state.installationId)

      await new Promise<void>(
        (resolve) => {
          Papa.parse(file, {
            header: true,
            skipEmptyLines: true,
            // Stream each row and add values to the CsvFile
            step: (results, parser) => {
              if (results.errors.length) {
                commit('setFileError', FileError.Unknown)

                if (results.errors.length === 1 && results.errors[0].type === 'FieldMismatch') {
                  commit('setFileError', FileError.InvalidRowLength)
                }

                parser.abort()
              }

              csvFile.setHeaders(results.meta.fields)

              const values = []

              for (const header of csvFile.getHeaders()) {
                const value = get(results.data, header, null)

                if (value !== null) {
                  values.push(value as string)
                }
              }

              csvFile.addRow(values)

              if (csvFile.getRows().length >= maxRows) {
                parser.abort()
              }
            },
            complete () {
              resolve()
            }
          })
        }
      )

      if (state.fileError !== FileError.None) {
        return
      }

      commit('setCsvFile', csvFile)
    },
    // Sets the file in storage and prepares for pre-validation
    // @TODO: this causes the test to fail with an error when triggering click on the continue button, due to promise not resolving before expect is called - need to figure this out and add trigger click, expect url to be called with `/${store.state.filename}`
    async getMappedCsv (
      { state, commit }: { state: IntelligentMatchState ; commit: Commit }
    ) {
      if (state.uploadedFile) {
        commit('createCrmDb')

        if (state.database) {
          state.database.version(1).stores({ crmData: `++,${Object.values(state.mappingConfig).join(',')}` })
        }

        await new Promise<void>(
          (resolve) => {
            Papa.parse(state.uploadedFile as File, {
              header: true,
              skipEmptyLines: true,
              transform (value: string, field: string): string | undefined {
                if (Object.values(state.mappingConfig).includes(field)) {
                  return value
                }

                return undefined
              },
              transformHeader (header: string): string {
                if (state.mappingConfig[header]) {
                  return state.mappingConfig[header]
                }

                return header
              },
              // Process the file in 10MB chunks. This is faster than streaming for larger files.
              chunk: async (results) => {
                // @ts-ignore
                state.database.crmData.bulkAdd(results.data)
              },
              complete () {
                resolve()
              }
            })
          }
        )

        // @ts-ignore
        let data = await state.database.crmData.toArray()
        data = map(data, (row: { [k: string]: string }) => {
          return omitBy(row, isUndefined)
        })
        const csv = Papa.unparse(data)
        const mappedFile = new File([csv], get(state, 'uploadedFile.name', 'transactions.csv'), { type: 'text/csv' })

        commit('setMappedFile', mappedFile)
        commit('deleteCrmDb')
      }
    },
    async getUploadUrl ({ state, commit }: { state: IntelligentMatchState; commit: Commit }) {
      if (state.installationId === '') {
        return
      }

      const headers = AuthUtil.authenticatedHeaders

      if (headers) {
        delete headers['x-auth-token']
      }

      const data = {}
      if (state.formatConfig) {
        Object.assign(data, state.formatConfig)
      }

      const response = await axios.post(
        `${Api.IM}/upload`,
        data,
        {
          headers,
          params: { installationId: state.installationId }
        }
      )

      const { url, filename } = get(response, 'data.data', {})

      if (!url || !filename) {
        return
      }

      commit('setUploadUrl', url)
      commit('setFilename', filename)
    },
    async preValidateFile ({ state, commit }: { state: IntelligentMatchState; commit: Commit }) {
      if (state.csvFile) {
        const validationRepo = new ValidationRepo()

        const results = await validationRepo.create(state.csvFile)

        if (!results) {
          return
        }

        const validationResult = new FileValidation().fromApiTransformer(results, Api.IM)
        validationResult.setInstallationId(state.installationId)

        commit('setFileValidation', validationResult)

        if (validationResult.getUrl() === null) {
          commit('setStatus', IntelligentMatchStatus.Failed)
        } else {
          commit('setStatus', IntelligentMatchStatus.Success)
        }
      }
    },
    async getValidationResults ({ state, commit }: { state: IntelligentMatchState; commit: Commit }) {
      if (state.filename && state.installationId) {
        const validationRepo = new ValidationResultsRepo()
        const results = await validationRepo.getResults(state.installationId, state.filename)

        if (results === null) {
          commit('setValidationResults', null)
        }

        commit('setValidationResults', results)
        commit('setStatus', IntelligentMatchStatus.Success)
      }
    },
    async uploadFile ({ state }: { state: IntelligentMatchState }) {
      if (state.mappedFile && state.uploadUrl) {
        const uploadRepo = new S3Repository(state.uploadUrl)

        const dateTimeFormatHeaders = {}
        if (state.formatConfig && state.formatConfig.datetimeFormat) {
          Object.assign(dateTimeFormatHeaders, { 'x-amz-meta-datetime-format': state.formatConfig.datetimeFormat })
        }
        if (state.formatConfig && state.formatConfig.callStartDatetimeFormat) {
          Object.assign(dateTimeFormatHeaders, { 'x-amz-meta-call-start-datetime-format': state.formatConfig.callStartDatetimeFormat })
        }
        if (state.formatConfig && state.formatConfig.timezone) {
          Object.assign(dateTimeFormatHeaders, { 'x-amz-meta-timezone': state.formatConfig.timezone })
        }
        if (state.formatConfig && state.formatConfig.phoneNumberCountryCode) {
          Object.assign(dateTimeFormatHeaders, { 'x-amz-meta-phone-number-country-code': state.formatConfig.phoneNumberCountryCode })
        }

        return await uploadRepo.upload(state.mappedFile, dateTimeFormatHeaders)
      }
    },
    async submitBatch ({ state }: { state: IntelligentMatchState }) {
      if (state.installationId && state.validationResults && state.validationResults.getBatchId()) {
        const batchId = state.validationResults.getBatchId()
        const submitBatchesRepo = new BatchRepo()
        return await submitBatchesRepo.submitBatch(state.installationId, batchId)
      }
    },
    validateHeaders ({ state, commit }: { state: IntelligentMatchState; commit: Commit }) {
      if (state.csvFile) {
        for (const header of state.csvFile.getHeaders()) {
          if (!Object.values(IntelligentMatchField).includes(header as IntelligentMatchField)) {
            let isAllowed = false
            for (const field of AllowedMultipleFields) {
              if (header.startsWith(field)) {
                isAllowed = true
                break
              }
            }

            if (!isAllowed) {
              commit('addInvalidHeader', header)
            }
          }

          if (state.invalidHeaders.length) {
            commit('setFileError', FileError.InvalidHeaders)
          }
        }
      }
    },
    async getBatchFile (
      { state, commit }: { state: IntelligentMatchState; commit: Commit },
      { filename }: { filename: string }
    ) {
      if (state.installationId) {
        const resultsRepo = new ResultsRepo()
        const results = await resultsRepo.getResults(state.installationId, filename)

        if (results === null) {
          commit('setBatchFile', null)
        }

        commit('setBatchFile', results)
      }
    },
    mappingHasUpdates ({ commit }: { state: IntelligentMatchState; commit: Commit }, hasChanges: boolean) {
      commit('setHasMappingChanges', hasChanges)
    },
    async storeMapping ({ state, commit }: { state: IntelligentMatchState; commit: Commit }) {
      const batchId = state.validationResults?.getBatchId()

      if (state.installationId && batchId && state.hasMappingChanges) {
        const saveMappingsRepo = new MappingsRepo()
        const data = await saveMappingsRepo.submitMapping(state.installationId, batchId, state.mappingConfig, state.formatConfig)

        commit('setMappingId', data?.mappingId)
        const bootStrapToaster = new BToast()

        bootStrapToaster.$bvToast.toast('We have saved your used mapping for future use.', {
          title: 'Saved mapping',
          variant: 'success',
          autoHideDelay: 5000
        })
      }
    },
    async getPreferences ({ state, commit }: { state: IntelligentMatchState; commit: Commit }) {
      if (state.installationId) {
        const preferencesRepo = new PreferencesRepo()
        const preferences = await preferencesRepo.getPreferences(state.installationId)

        commit('setPreferences', preferences)
      }
    },
    async getSavedMapping ({ state, commit }: { state: IntelligentMatchState; commit: Commit }) {
      const saveMappingsRepo = new MappingsRepo()
      const response = await saveMappingsRepo.fetchMapping(state.installationId)

      if (response?.data?.mapping?.length) {
        // @TODO: Data comes from API as a stringified JSON object, which needs to be updated so it comes as an object we can use here directly without having to parse it.
        try {
          commit('setSavedMappingIsFound', true)
          const mappingData: { [key: string]: string }[] = JSON.parse(response.data.mapping)
          let mappingConfig: { [key: string]: string } = {}
          let formatConfig: { [key: string]: null|string } = JSON.parse(response.data.config)
          // get the headers from the csv to compare against the saved mapping
          const csvHeaders = state.csvFile?.getHeaders()

          mappingData.forEach((field) => {
            for (const [key, value] of Object.entries(field)) {
              // check header exists in csv. If not, the csv headers have changed, so return and force re-mapping in the UI.
              if (!csvHeaders?.includes(key)) {
                commit('setCsvMatchesSavedMapping', false)
                mappingConfig = {}
                return false
              }
              mappingConfig[key] = value
            }
          })

          if (!state.csvMatchesSavedMapping) {
            mappingConfig = {}
            formatConfig = {
              datetimeFormat: null,
              callStartDatetimeFormat: null,
              timezone: null,
              phoneNumberCountryCode: null
            }

            if (!state.csvMatchesSavedMapping) {
              const bootStrapToaster = new BToast()

              bootStrapToaster.$bvToast.toast('Your uploaded file has changed since last time, re-map your fields below or go back and upload a new file.', {
                title: 'Error',
                variant: 'danger',
                autoHideDelay: 5000
              })
            }
          }

          commit('setMappingConfig', mappingConfig)
          commit('setFormatConfig', formatConfig)
        } catch (error) {
          // return false to trigger the toast popup error message
          return false
        }
      }
    },
    updateMappingConfig (
      { commit }: { commit: Commit },
      mappingConfig
    ) {
      commit('setMappingConfig', mappingConfig)
    },
    async getTrackingPoolIds ({ state, commit }: { state: IntelligentMatchState; commit: Commit }) {
      if (state.installationId) {
        const trackingPoolRepo = new TrackingPoolRepo(parseInt(state.installationId))
        const trackingPoolIds = await trackingPoolRepo.getTrackingPoolIds()

        commit('setTrackingPoolIds', trackingPoolIds)
      }
    },
    async getTrackingPools (
      { state, commit }: { state: IntelligentMatchState; commit: Commit },
      { filter = null, append = false, offset = 0 }: { filter: string | null; append: boolean; offset: number }
    ) {
      if (state.installationId) {
        const trackingPoolRepo = new TrackingPoolRepo(parseInt(state.installationId))
        const trackingPools = await trackingPoolRepo.getActiveTrackingPools(filter, offset)

        if (append) {
          if (trackingPools && trackingPools.length > 0) {
            commit('addTrackingPools', trackingPools)
          }
        } else {
          commit('setTrackingPools', trackingPools)
        }
      }
    },
    async getFixedNumberIds ({ state, commit }: { state: IntelligentMatchState; commit: Commit }) {
      if (state.installationId) {
        const fixedNumbersRepo = new FixedNumberRepo(parseInt(state.installationId))
        const fixedNumberIds = await fixedNumbersRepo.getFixedNumberIds()

        commit('setFixedNumberIds', fixedNumberIds)
      }
    },
    async getFixedNumbers (
      { state, commit }: { state: IntelligentMatchState; commit: Commit },
      { filter = null, append = false, offset = 0 }: { filter: string | null; append: boolean; offset: number }
    ) {
      if (state.installationId) {
        const fixedNumbersRepo = new FixedNumberRepo(parseInt(state.installationId))
        const fixedNumbers = await fixedNumbersRepo.getFixedNumbers(filter, offset)

        if (append) {
          if (fixedNumbers && fixedNumbers.length > 0) {
            commit('addFixedNumbers', fixedNumbers)
          }
        } else {
          commit('setFixedNumbers', fixedNumbers)
        }
      }
    },
    async updatePreferences (
      { state, commit }: { state: IntelligentMatchState; commit: Commit },
      { updatePreferenceRequest }: { updatePreferenceRequest: UpdatePreferenceRequest }
    ) {
      const preferencesRepo = new PreferencesRepo()
      const preferences = await preferencesRepo.updatePreferences(state.installationId, updatePreferenceRequest)

      if (preferences) {
        commit('setPreferences', preferences)
      }
    },
    reset ({ commit }: { commit: Commit }) {
      commit('setStatus', IntelligentMatchStatus.None)
      commit('setUploadUrl', '')
      commit('setFilename', '')
      commit('setCsvFile', null)
      commit('setUploadedFile', null)
      commit('setFileValidation', null)
      commit('setFileError', FileError.None)
      commit('setValidationResults', null)
      commit('resetInvalidHeaders')
      commit('resetMappingConfig')
      commit('setMappedFile', null)
      commit('deleteCrmDb')
      commit('setBatchFile', null)
      commit('setFormatConfig', null)
      commit('setHasMappingChanges', false)
      commit('setCsvMatchesSavedMapping', true)
      commit('setSavedMappingIsFound', false)
    }
  }
})
