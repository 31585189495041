import Model, { ApiFieldDefinition, apiToModelTransformer, modelToApiTransformer } from '@infinity/shared/models/base'
import { Api, JsonData } from '@infinity/shared/helpers/api'

export default class FixedNumber extends Model {
  // TODO: Use Data Dictionary
  protected fieldDefinition: ApiFieldDefinition = {
    [Api.Hub]: {
      phoneId: 'id',
      phoneNumber: 'phoneNumber',
      usageFilter: 'description',
      dgrp: 'trackingPoolId',
      dgrpName: 'trackingPoolName',
      igrp: 'installationId'
    }
  }

  private id = ''
  private phoneNumber = ''
  private description = ''
  private trackingPoolId = ''
  private trackingPoolName = ''
  private installationId = ''

  getId (): string {
    return this.id
  }

  setId (id: string): FixedNumber {
    this.id = id

    return this
  }

  getPhoneNumber (): string {
    return this.phoneNumber
  }

  setPhoneNumber (phoneNumber: string): FixedNumber {
    this.phoneNumber = phoneNumber

    return this
  }

  getDescription (): string {
    return this.description
  }

  setDescription (description: string): FixedNumber {
    this.description = description

    return this
  }

  getTrackingPoolId (): string {
    return this.trackingPoolId
  }

  setTrackingPoolId (trackingPoolId: string): FixedNumber {
    this.trackingPoolId = trackingPoolId

    return this
  }

  getTrackingPoolName (): string {
    return this.trackingPoolName
  }

  setTrackingPoolName (trackingPoolName: string): FixedNumber {
    this.trackingPoolName = trackingPoolName

    return this
  }

  getInstallationId (): string {
    return this.installationId
  }

  setInstallationId (installationId: string): FixedNumber {
    this.installationId = installationId

    return this
  }

  fromApiTransformer (data: JsonData, api: Api): FixedNumber {
    apiToModelTransformer(this, data, this.fieldDefinition[api])

    return this
  }

  toApiTransformer (api: Api): JsonData {
    return modelToApiTransformer(this, this.fieldDefinition[api])
  }
}
