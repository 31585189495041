var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { staticClass: "mt-4" },
    [
      _c(
        "b-row",
        { staticClass: "justify-content-md-center" },
        [
          _vm.isLoading || _vm.isPollingTimedOut
            ? _c("b-col", { attrs: { cols: "12", lg: "6" } }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "test",
                        rawName: "v-test",
                        value: "loading",
                        expression: "'loading'"
                      }
                    ],
                    staticClass:
                      "bg-light p-5 mt-5 rounded-lg d-flex flex-column align-items-center"
                  },
                  [
                    _vm.isLoading
                      ? [
                          _c("b-spinner"),
                          _c("h4", { staticClass: "mt-3" }, [
                            _vm._v("Validating your file...")
                          ]),
                          _c("p", { staticClass: "mb-0" }, [
                            _vm._v("This may take a while")
                          ])
                        ]
                      : _vm._e(),
                    _vm.isPollingTimedOut
                      ? [
                          _c("h4", { staticClass: "mt-3" }, [
                            _vm._v("This is taking longer than usual")
                          ]),
                          _c("p", { staticClass: "mb-3" }, [
                            _vm._v(
                              "It appears we haven't yet validated your file. This can be normal for larger uploads. You can try reloading this page to keep checking, or try uploading a smaller file."
                            )
                          ]),
                          _c(
                            "b-button",
                            {
                              directives: [
                                {
                                  name: "test",
                                  rawName: "v-test",
                                  value: "timeout reset button",
                                  expression: "'timeout reset button'"
                                }
                              ],
                              on: { click: _vm.doReset }
                            },
                            [
                              _c("b-icon-arrow-clockwise", {
                                staticClass: "mr-2"
                              }),
                              _vm._v(" Choose a different file ")
                            ],
                            1
                          )
                        ]
                      : _vm._e()
                  ],
                  2
                )
              ])
            : _vm.resultsMeta
            ? _c("b-col", { attrs: { cols: "12", lg: "10" } }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "rounded-lg bg-white d-flex p-3 align-items-center w-100"
                  },
                  [
                    _c(
                      "h1",
                      { staticClass: "mb-0 mr-3" },
                      [_c("b-icon-percent")],
                      1
                    ),
                    _c("div", { staticClass: "flex-1 mr-3" }, [
                      _c("h5", [_vm._v("Data Quality")]),
                      _c("p", { staticClass: "mb-0" }, [
                        _vm._v(
                          "Percentage of the rows you provided that have enough information to be matched against a call."
                        )
                      ])
                    ]),
                    _c(
                      "div",
                      {
                        staticClass:
                          "ml-auto d-flex flex-column align-items-center"
                      },
                      [
                        _c(
                          "h3",
                          {
                            directives: [
                              {
                                name: "test",
                                rawName: "v-test",
                                value: "match rate",
                                expression: "'match rate'"
                              }
                            ],
                            staticClass: "mb-0"
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.resultsMeta
                                  .getPotentialMatchPercent()
                                  .toFixed(2)
                              ) + "%"
                            )
                          ]
                        ),
                        _c("small", { staticClass: "test-center" }, [
                          _vm._v(
                            "of " +
                              _vm._s(_vm.resultsMeta.getTotalCount()) +
                              " rows"
                          )
                        ])
                      ]
                    )
                  ]
                ),
                _vm.resultsMeta.getWarningCount()
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "rounded-lg bg-white d-flex p-3 align-items-center w-100 mt-3"
                      },
                      [
                        _c(
                          "h1",
                          { staticClass: "mb-0 mr-3" },
                          [
                            _c("b-icon-exclamation-diamond-fill", {
                              attrs: { variant: "warning" }
                            })
                          ],
                          1
                        ),
                        _c("div", { staticClass: "flex-1 mr-3" }, [
                          _c("h5", [_vm._v("Warnings")]),
                          _c("p", { staticClass: "mb-0" }, [
                            _vm._v(
                              "The number of rows that have a reduced chance of being matched due to missing information."
                            )
                          ]),
                          _c("p", { staticClass: "mb-0 text-whale" }, [
                            _vm._v("These rows will still be processed.")
                          ])
                        ]),
                        _c(
                          "div",
                          {
                            staticClass:
                              "ml-auto d-flex flex-column align-items-center"
                          },
                          [
                            _c(
                              "h3",
                              {
                                directives: [
                                  {
                                    name: "test",
                                    rawName: "v-test",
                                    value: "warning count",
                                    expression: "'warning count'"
                                  }
                                ],
                                staticClass: "mb-0"
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.resultsMeta.getWarningCount())
                                )
                              ]
                            ),
                            _c("small", { staticClass: "test-center" }, [
                              _vm._v(
                                "of " +
                                  _vm._s(_vm.resultsMeta.getTotalCount()) +
                                  " rows"
                              )
                            ])
                          ]
                        )
                      ]
                    )
                  : _vm._e(),
                _vm.resultsMeta.getErrorCount()
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "rounded-lg bg-white d-flex p-3 align-items-center w-100 mt-3"
                      },
                      [
                        _c(
                          "h1",
                          { staticClass: "mb-0 mr-3" },
                          [
                            _c("b-icon-exclamation-triangle-fill", {
                              attrs: { variant: "danger" }
                            })
                          ],
                          1
                        ),
                        _c("div", { staticClass: "flex-1 mr-3" }, [
                          _c("h5", [_vm._v("Errors")]),
                          _c("p", { staticClass: "mb-0" }, [
                            _vm._v(
                              "The number of rows missing required information or data that's in a format we didn't expect."
                            )
                          ]),
                          _c("p", { staticClass: "mb-0 text-whale" }, [
                            _vm._v("These rows will be ignored.")
                          ])
                        ]),
                        _c(
                          "div",
                          {
                            staticClass:
                              "ml-auto d-flex flex-column align-items-center"
                          },
                          [
                            _c(
                              "h3",
                              {
                                directives: [
                                  {
                                    name: "test",
                                    rawName: "v-test",
                                    value: "error count",
                                    expression: "'error count'"
                                  }
                                ],
                                staticClass: "mb-0"
                              },
                              [_vm._v(_vm._s(_vm.resultsMeta.getErrorCount()))]
                            ),
                            _c("small", { staticClass: "test-center" }, [
                              _vm._v(
                                "of " +
                                  _vm._s(_vm.resultsMeta.getTotalCount()) +
                                  " rows"
                              )
                            ])
                          ]
                        )
                      ]
                    )
                  : _vm._e(),
                _vm.resultsMeta.getWarningCount() ||
                _vm.resultsMeta.getErrorCount()
                  ? _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "test",
                            rawName: "v-test",
                            value: "validation file",
                            expression: "'validation file'"
                          }
                        ],
                        staticClass: "bg-light mt-4 p-3 rounded-lg"
                      },
                      [
                        _c("h5", [_vm._v("Validation File")]),
                        _c(
                          "p",
                          [
                            _vm._v(
                              "We've prepared a copy of your CSV with any warnings or errors that were found. "
                            ),
                            _vm.resultsMeta.getPotentialMatchPercent() !== 0
                              ? [
                                  _vm._v(
                                    "You may check this file before choosing how to proceed."
                                  )
                                ]
                              : _vm._e()
                          ],
                          2
                        ),
                        _c(
                          "p",
                          { staticClass: "text-center mb-0" },
                          [
                            _c(
                              "b-button",
                              {
                                directives: [
                                  {
                                    name: "test",
                                    rawName: "v-test",
                                    value: "file download",
                                    expression: "'file download'"
                                  }
                                ],
                                attrs: {
                                  variant: "info",
                                  type: "download",
                                  href: _vm.$store.state.validationResults.getUrl()
                                }
                              },
                              [
                                _c("b-icon-cloud-download", {
                                  staticClass: "mr-2"
                                }),
                                _vm._v(" View Validation File")
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    )
                  : _vm._e(),
                _vm.resultsMeta.getPotentialMatchPercent() === 0
                  ? _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "test",
                            rawName: "v-test",
                            value: "no potential matches",
                            expression: "'no potential matches'"
                          }
                        ],
                        staticClass: "bg-white mt-4 p-3 rounded-lg"
                      },
                      [
                        _c(
                          "h5",
                          [
                            _c("b-icon-exclamation-triangle-fill", {
                              staticClass: "mr-1",
                              attrs: { variant: "danger" }
                            }),
                            _vm._v(" Unable to continue")
                          ],
                          1
                        ),
                        _c("p", { staticClass: "mb-0" }, [
                          _vm._v(
                            "We cannot process a file with a potential match rate of 0%. Please use the Validation File to fix any errors that we found and try again."
                          )
                        ])
                      ]
                    )
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "d-flex justify-content-between w-100 mt-4" },
                  [
                    _c(
                      "b-button",
                      {
                        directives: [
                          {
                            name: "test",
                            rawName: "v-test",
                            value: "reset button",
                            expression: "'reset button'"
                          }
                        ],
                        on: { click: _vm.doReset }
                      },
                      [
                        _c("b-icon-arrow-clockwise", { staticClass: "mr-2" }),
                        _vm._v(" Choose a different file ")
                      ],
                      1
                    ),
                    _c(
                      "b-button",
                      {
                        directives: [
                          {
                            name: "test",
                            rawName: "v-test",
                            value: "continue button",
                            expression: "'continue button'"
                          }
                        ],
                        attrs: {
                          variant: "success",
                          disabled:
                            _vm.resultsMeta.getPotentialMatchPercent() === 0 ||
                            _vm.isQueuing
                        },
                        on: { click: _vm.doQueue }
                      },
                      [
                        !_vm.isQueuing
                          ? _c("b-icon-cloud-upload", { staticClass: "mr-2" })
                          : _c("b-spinner", {
                              staticClass: "mr-2",
                              attrs: { small: "" }
                            }),
                        _vm._v(" Continue with this file ")
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }