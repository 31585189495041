import Model, { ApiFieldDefinition, apiToModelTransformer } from '@infinity/shared/models/base'
import { Api, JsonData } from '@infinity/shared/helpers/api'

interface ValidationError {
  field: string;
  value?: string;
  message: string;
}

export default class FileValidation extends Model {
  protected fieldDefinition: ApiFieldDefinition = {
    [Api.IM]: {
      validationResults: 'validationResults',
      url: 'url',
      filename: 'filename'
    }
  }

  private validationResults: { [k: number]: Array<ValidationError> } = {}
  private url: string | null = null
  private filename: string | null = null
  private installationId: string | null = null

  getValidationResults (): { [k: number]: Array<ValidationError> } {
    return this.validationResults
  }

  setValidationResults (validationResults: { [k: number]: Array<ValidationError> } = {}): FileValidation {
    this.validationResults = validationResults

    return this
  }

  getUrl (): string | null {
    return this.url
  }

  setUrl (url: string | null = null): FileValidation {
    this.url = url

    return this
  }

  getFilename (): string | null {
    return this.filename
  }

  setFilename (filename: string | null = null): FileValidation {
    this.filename = filename

    return this
  }

  getInstallationId (): string | null {
    return this.installationId
  }

  setInstallationId (installationId: string | null = null): FileValidation {
    this.installationId = installationId

    return this
  }

  fromApiTransformer (data: JsonData, api: Api): FileValidation {
    if (data.data) {
      apiToModelTransformer(this, data.data as JsonData, this.fieldDefinition[api])
    }

    return this
  }

  toApiTransformer (): JsonData {
    return {
      filename: this.getFilename(),
      installationId: this.getInstallationId()
    }
  }
}
