


























































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import {
  BIconArrowClockwise,
  BIconCollectionFill,
  BIconSliders,
  BIconTelephoneFill,
  BSpinner,
  BFormSpinbutton,
  ButtonPlugin,
  FormInputPlugin,
  FormSelectPlugin,
  InputGroupPlugin,
  BFormTags
} from 'bootstrap-vue'
import CheckboxGroup, { CheckboxGroupType } from '@/components/CheckboxGroup.vue'
import { NumberScope, LookbackIntervals, Lookback, UpdatePreferenceRequest } from '@/models/Preference'
import TrackingPool from '@/models/TrackingPool'
import FixedNumber from '@/models/FixedNumber'

Vue.use(ButtonPlugin)
Vue.use(FormSelectPlugin)
Vue.use(FormInputPlugin)
Vue.use(InputGroupPlugin)

export class PreferencesConfig {
  selected!: number[]
  excludeFixedNumbers!: boolean
}

@Component({
  components: {
    BSpinner,
    BIconSliders,
    BIconArrowClockwise,
    BIconTelephoneFill,
    BIconCollectionFill,
    CheckboxGroup,
    BFormTags,
    BFormSpinbutton
  }
})
export default class Preferences extends Vue {
  readonly NumberScope = NumberScope
  readonly CheckboxGroupType = CheckboxGroupType

  private isLoading = false
  private failedToLoad = false

  private trackingPoolIds: number[] = []
  private fixedNumberIds: number[] = []

  private trackingPoolOptions: { text: string; value: number }[] = []
  private fixedNumberOptions: { text: string; value: number }[] = []

  private numberScope = NumberScope.ALL_NUMBERS

  private lookbackInterval = LookbackIntervals[0]
  private lookbackIntervalOptions: Lookback[] = LookbackIntervals

  private lookbackHours = 720
  private sameDayMatching = false
  private lookForward = false
  private excludeDatetimeOnly = false
  private notificationEmails: string[] = []

  private lookbackSecondsInputMins = 0
  private lookbackSecondsInputSecs = 0

  get lookbackSeconds () {
    if (this.lookbackHours !== 0) {
      return 0
    }

    return (this.lookbackSecondsInputMins * 60) + this.lookbackSecondsInputSecs
  }

  private trackingPoolConfig: PreferencesConfig = {
    selected: [],
    excludeFixedNumbers: false
  }

  private fixedNumberConfig: PreferencesConfig = {
    selected: [],
    excludeFixedNumbers: false
  }

  async created () {
    await this.setupPreferences(true)
  }

  async setupPreferences (loadAll = false) {
    this.isLoading = true

    // Load preferences
    try {
      await this.$store.dispatch('getPreferences')
    } catch (e) {
      this.failedToLoad = true
      this.isLoading = false

      return
    }

    // Get tracking pools for the installation
    if (loadAll || !this.$store.state.trackingPools) {
      try {
        await this.$store.dispatch('getTrackingPoolIds')
        await this.$store.dispatch('getTrackingPools', {})
      } catch (e) {
        this.failedToLoad = true
        this.isLoading = false

        return
      }

      this.trackingPoolIds = this.$store.state.trackingPoolIds
    }

    // Get fixed numbers for the installation
    if (loadAll || !this.$store.state.fixedNumbers) {
      try {
        await this.$store.dispatch('getFixedNumberIds')
        await this.$store.dispatch('getFixedNumbers', {})
      } catch (e) {
        this.failedToLoad = true
        this.isLoading = false

        return
      }

      this.fixedNumberIds = this.$store.state.fixedNumberIds
    }

    this.numberScope = NumberScope[this.$store.state.preferences.configuration.numberScope as keyof typeof NumberScope]

    this.sameDayMatching = this.$store.state.preferences.configuration.sameDayMatching

    this.lookForward = this.$store.state.preferences.configuration.lookForward

    this.lookbackHours = this.$store.state.preferences.configuration.lookbackHours

    this.lookbackSecondsInputMins = Math.floor(this.$store.state.preferences.configuration.lookbackSeconds / 60)

    this.lookbackSecondsInputSecs = this.$store.state.preferences.configuration.lookbackSeconds - (this.lookbackSecondsInputMins * 60)

    this.excludeDatetimeOnly = this.$store.state.preferences.configuration.excludeDatetimeOnly

    this.notificationEmails = this.$store.state.preferences.configuration.notificationEmails

    const filteredLookbackIntervals = LookbackIntervals.filter(li => li.value === this.lookbackHours)
    if (filteredLookbackIntervals.length === 0) {
      const customInterval = { text: 'Custom', value: this.lookbackHours }
      this.lookbackInterval = customInterval
      this.lookbackIntervalOptions.push(customInterval)
    } else {
      this.lookbackInterval = filteredLookbackIntervals[0]
    }

    this.trackingPoolConfig = {
      selected: this.$store.state.preferences.configuration.trackingPoolIds,
      excludeFixedNumbers: this.$store.state.preferences.configuration.excludeFixedNumbers
    }

    this.fixedNumberConfig = {
      selected: this.$store.state.preferences.configuration.fixedNumberIds,
      excludeFixedNumbers: false
    }

    this.isLoading = false
  }

  @Watch('$store.state.trackingPools')
  setupTrackingPoolOptions (trackingPools: TrackingPool[]) {
    const trackingPoolOptions = []
    for (const trackingPool of trackingPools) {
      trackingPoolOptions.push({ text: trackingPool.getName(), value: parseInt(trackingPool.getId()) })
    }

    this.trackingPoolOptions = trackingPoolOptions
  }

  @Watch('$store.state.fixedNumbers')
  setupFixedNumberOptions (fixedNumbers: FixedNumber[]) {
    const fixedNumberOptions = []
    for (const fixedNumber of fixedNumbers) {
      fixedNumberOptions.push({
        text: fixedNumber.getDescription() ? `${fixedNumber.getDescription()} (${fixedNumber.getPhoneNumber()})` : `${fixedNumber.getPhoneNumber()}`,
        value: parseInt(fixedNumber.getId())
      })
    }

    this.fixedNumberOptions = fixedNumberOptions
  }

  updateNumberScope (numberScope: NumberScope) {
    this.numberScope = numberScope
  }

  updateNotificationEmails (notificationEmails: string[]) {
    this.notificationEmails = notificationEmails
  }

  updateLookback (lookback: Lookback) {
    this.lookbackInterval = lookback
    this.lookbackHours = lookback.value

    if (lookback.value !== 0) {
      this.lookbackSecondsInputMins = 0
      this.lookbackSecondsInputSecs = 0
    }
  }

  unsetLookForward () {
    // value hasn't updated yet so check for off -> on
    if (!this.sameDayMatching) {
      this.lookForward = false

      this.$nextTick()
    }
  }

  async doSave () {
    const updatePreferenceRequest: Partial<UpdatePreferenceRequest> = {
      numberScope: Object.keys(NumberScope).find((key) => { return NumberScope[key as keyof typeof NumberScope] === this.numberScope }) as NumberScope,
      trackingPoolIds: this.trackingPoolConfig.selected,
      fixedNumberIds: this.fixedNumberConfig.selected,
      excludeFixedNumbers: this.trackingPoolConfig.excludeFixedNumbers,
      sameDayMatching: this.sameDayMatching,
      lookForward: this.lookForward,
      excludeDatetimeOnly: this.excludeDatetimeOnly,
      notificationEmails: this.notificationEmails
    }

    if (this.lookbackSeconds === 0) {
      updatePreferenceRequest.lookbackHours = this.lookbackHours
    }

    if (this.lookbackHours === 0) {
      updatePreferenceRequest.lookbackSeconds = this.lookbackSeconds
    }

    try {
      await this.$store.dispatch('updatePreferences', { updatePreferenceRequest })

      this.$bvToast.toast('Your preferences have been saved.', {
        title: 'Success',
        variant: 'success',
        autoHideDelay: 5000
      })
    } catch (e) {
      this.$bvToast.toast('We encountered a problem saving your preferences. Please try again.', {
        title: 'Error',
        variant: 'danger',
        autoHideDelay: 5000
      })
    }

    await this.$nextTick()
  }

  validateEmail (tag: string): boolean {
    // borrowed from https://html.spec.whatwg.org/multipage/input.html#email-state-(type=email)
    return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/.test(tag)
  }
}
