























































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { BIconSearch, BIconThreeDots, FormInputPlugin, InputGroupPlugin } from 'bootstrap-vue'
import { PreferencesConfig } from '@/views/Preferences.vue'

Vue.use(FormInputPlugin)
Vue.use(InputGroupPlugin)

export enum CheckboxGroupType {
  Pools = 'Tracking Pools',
  FixedNumbers = 'Fixed Numbers'
}

@Component({
  components: {
    BIconSearch,
    BIconThreeDots
  }
})
export default class CheckboxGroup extends Vue {
  @Prop({ required: true })
  type!: CheckboxGroupType

  @Prop({ required: true, type: Array })
  options!: { text: string; value: number }[]

  @Prop({ required: true, type: Object })
  preferences!: PreferencesConfig

  @Prop({ required: true, type: Array })
  allValues!: number[]

  readonly CheckboxGroupType = CheckboxGroupType

  private isLoading = false
  private isLoadingMore = false

  private allSelected = false
  private indeterminate = false
  private filter = ''

  created () {
    this.allSelected = this.preferences.selected.length === this.allValues.length
    this.indeterminate = !this.allSelected && this.preferences.selected.length > 0
  }

  toggleAll (checked: boolean) {
    Vue.set(this.preferences, 'selected', checked ? this.allValues : [])
  }

  toggleExcludeFixedNumbers (checked: boolean) {
    Vue.set(this.preferences, 'excludeFixedNumbers', checked)
  }

  async loadAdditionalOptions () {
    this.isLoadingMore = true

    if (this.type === CheckboxGroupType.Pools) {
      await this.$store.dispatch('getTrackingPools', { filter: this.filter, append: true, offset: this.options.length })
    } else {
      await this.$store.dispatch('getFixedNumbers', { filter: this.filter, append: true, offset: this.options.length })
    }

    this.isLoadingMore = false
  }

  @Watch('preferences.selected')
  updateSelected (newValue: number[]) {
    // Handle changes in individual checkboxes
    if (newValue.length === 0) {
      this.indeterminate = false
      this.allSelected = false
    } else if (newValue.length === this.allValues.length) {
      this.indeterminate = false
      this.allSelected = true
    } else {
      this.indeterminate = true
      this.allSelected = false
    }
  }

  @Watch('filter')
  async updateFilter (value: string) {
    if (value.length >= 3 || value === '') {
      this.isLoading = true

      if (this.type === CheckboxGroupType.Pools) {
        await this.$store.dispatch('getTrackingPools', { filter: this.filter })
      } else {
        await this.$store.dispatch('getFixedNumbers', { filter: this.filter })
      }

      this.isLoading = false
    }
  }
}
