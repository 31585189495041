import Repository from '@infinity/shared/repos/base'
import { Api } from '@infinity/shared/helpers/api'
import InstallationConfig from '@/models/InstallationConfig'
import axios from 'axios'

export default class InstallationConfigRepo extends Repository {
  constructor () {
    super(Api.Hub, 'config/igrps', InstallationConfig, 'igrpConfig')
  }

  async find (id: string | number): Promise<InstallationConfig | null> {
    const model = await super.find(id)

    if (!(model instanceof InstallationConfig)) {
      return null
    }

    return model
  }

  async updateVoidTriggers (id: string | number, value: boolean) {
    const voidUnmatchedCrmTriggers = value ? '1' : '0'
    const data = { igrpConfig: { voidUnmatchedCrmTriggers } }
    const response = await axios.post(
      `${this.api}/${this.apiRoute}/${id}`,
      data,
      {
        params: this.additionalParams,
        ...this.requestConfig
      }
    )

    if (!response.data) {
      return null
    }

    return response.data
  }
}
