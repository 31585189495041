var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "b-icon bi",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        fill: "currentColor",
        width: "1em",
        height: "1em",
        viewBox: "0 0 16 16",
        preserveAspectRatio: "none"
      }
    },
    [
      _c("g", [
        _c("path", {
          attrs: {
            opacity: _vm.ascOpacity,
            d: "m8.0792.1584 3.9604 3.6436 3.802 3.4851h-15.6832l3.9604-3.4851z"
          }
        }),
        _c("path", {
          attrs: {
            opacity: _vm.descOpacity,
            d: "m8.0792 16 3.9604-3.6436 3.802-3.4851h-15.6832l3.9604 3.4851z"
          }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }