import Repository, { DefaultLimit } from '@infinity/shared/repos/base'
import { Api } from '@infinity/shared/helpers/api'
import { Filter, FilterCollection, FilterOperand } from '@infinity/shared/helpers/filter'
import TrackingPool from '@/models/TrackingPool'
import axios from 'axios'

export default class TrackingPoolRepo extends Repository {
  constructor (installationId: number) {
    super(Api.Hub, `config/igrps/${installationId}/dgrps`, TrackingPool, 'dgrps')
  }

  async getTrackingPoolIds (): Promise<number[]> {
    const filters = new FilterCollection(new Filter('dgrpStatus', FilterOperand.Equals, '200'))

    const response = await axios.get(`${this.api}/${this.apiRoute}`, {
      params: { filters: filters.toApi() },
      ...this.requestConfig
    })

    const data = []
    if (response && response.data) {
      const items = response.data.dgrps

      if (items) {
        for (const item of items) {
          data.push(parseInt(item.dgrp))
        }
      }
    }

    return data
  }

  async getActiveTrackingPools (filter: string | null, offset: number): Promise<TrackingPool[] | null> {
    const filters = new FilterCollection(new Filter('dgrpStatus', FilterOperand.Equals, '200'))

    if (filter && filter !== '') {
      filters.addFilter(new Filter('dgrpName', FilterOperand.IncludesI, filter))
    }

    const model = await this.fetch(filters, DefaultLimit, offset)

    if (Array.isArray(model)) {
      model.forEach((model) => {
        if (!(model instanceof TrackingPool)) {
          return null
        }
      })

      return model as TrackingPool[]
    }

    return null
  }
}
