import axios from 'axios'

export default class S3Repository {
  protected url: string

  constructor (url: string) {
    this.url = url
  }

  upload (file: File, headers: { [k: string]: string } = {}): Promise<boolean> {
    return axios.put(this.url, file, {
      headers: {
        'Content-Type': 'text/csv',
        ...headers
      }
    })
  }
}
