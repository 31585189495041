import Repository from '@infinity/shared/repos/base'
import { Api, JsonData } from '@infinity/shared/helpers/api'
import Batch from '@/models/Batch'
import axios from 'axios'
import { get as lodashGet, isArray, isObject } from 'lodash'
import BatchResponse from '@/models/BatchResponse'

export default class BatchRepo extends Repository {
  constructor () {
    super(Api.IM, 'batches', Batch, 'data')
  }

  async getBatches (installationId: string, returnDeletedBatches = false, limit = 0, offset = 0, sort: string | null = null, sortDirection: string | null = null): Promise<Batch[] | BatchResponse | null> {
    const response = await axios.get(`${this.api}/${this.apiRoute}`, {
      params: {
        installationId,
        returnDeletedBatches,
        limit,
        offset,
        sort,
        sortDirection
      },
      ...this.requestConfig
    })

    if (response && response.data) {
      const data = lodashGet(response.data, this.getter as string, null)

      if (!data) {
        return null
      }

      if (isArray(data)) {
        const items: Batch[] = []

        for (const batch of data) {
          items.push(
            new Batch()
              .fromApiTransformer(batch as JsonData, this.api)
          )
        }

        return items
      }

      if (isObject(data)) {
        return new BatchResponse()
          .fromApiTransformer(data as JsonData, this.api)
      }
    }

    return null
  }

  get requestConfig (): { headers: { [k: string]: string | null } } | null {
    const headers = super.requestConfig
    if (!headers) {
      return null
    }

    // Remove as this violates IM CORS
    delete headers.headers['x-auth-token']

    return headers
  }

  async deleteBatches (installationId: string, batchId: string) {
    return await axios.delete(`${this.api}/${this.apiRoute}/${batchId}`,
      {
        params: { installationId },
        ...this.requestConfig
      }
    )
  }

  async submitBatch (installationId: string, batchId: string) {
    return await axios.post(`${this.api}/${this.apiRoute}/${batchId}`, null,
      {
        params: { installationId },
        ...this.requestConfig
      }
    )
  }
}
