var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    { attrs: { "align-v": "center" } },
    [
      _c(
        "b-col",
        [
          _c(
            "b-form-select",
            {
              staticClass: "mb-3 mappingText",
              attrs: {
                disabled: _vm.mapping.field.required,
                value: _vm.mapping.field
              },
              on: { change: _vm.updateField }
            },
            [
              !_vm.mapping.field.allowMultiple
                ? _c(
                    "b-form-select-option",
                    { attrs: { value: _vm.mapping.field } },
                    [_vm._v(_vm._s(_vm.mapping.field.label))]
                  )
                : _vm._e(),
              _vm._l(_vm.availableFields, function(field) {
                return _c(
                  "b-form-select-option",
                  { key: field.field, attrs: { value: field } },
                  [_vm._v(_vm._s(field.label))]
                )
              })
            ],
            2
          )
        ],
        1
      ),
      _c(
        "b-col",
        [
          _c(
            "b-form-select",
            {
              staticClass: "mb-3 mappingText",
              attrs: { value: _vm.mapping.header },
              on: { change: _vm.updateHeader }
            },
            [
              _c(
                "b-form-select-option",
                { attrs: { value: _vm.mapping.header } },
                [
                  _vm.mapping.header
                    ? [_vm._v(_vm._s(_vm.mapping.header))]
                    : [_vm._v("Please select a header")]
                ],
                2
              ),
              _vm._l(_vm.availableHeaders, function(header) {
                return _c(
                  "b-form-select-option",
                  { key: header, attrs: { value: header } },
                  [_vm._v(_vm._s(header))]
                )
              })
            ],
            2
          )
        ],
        1
      ),
      _c(
        "b-col",
        {
          directives: [
            {
              name: "test",
              rawName: "v-test",
              value: "preview",
              expression: "'preview'"
            }
          ],
          staticClass: "mb-3 mappingText"
        },
        [_vm._v(" " + _vm._s(_vm.preview) + " ")]
      ),
      _c(
        "b-col",
        [
          _vm.mapping.field.datetimeField
            ? _c(
                "b-form-select",
                {
                  staticClass: "mb-3 mappingText",
                  attrs: { value: _vm.mapping.datetimeFormat },
                  on: { change: _vm.updateDatetimeFormat }
                },
                [
                  _c(
                    "b-form-select-option",
                    { attrs: { value: _vm.mapping.datetimeFormat } },
                    [
                      _vm.formatConfig.datetimeFormat
                        ? [_vm._v(_vm._s(_vm.formatConfig.datetimeFormat))]
                        : [_vm._v("Please select a date format")]
                    ],
                    2
                  ),
                  _vm._l(
                    _vm.getAvailableDatetimeFormats(_vm.mapping.datetimeFormat),
                    function(datetimeFormat) {
                      return _c(
                        "b-form-select-option",
                        {
                          key: datetimeFormat,
                          attrs: { value: datetimeFormat }
                        },
                        [_vm._v(_vm._s(datetimeFormat))]
                      )
                    }
                  )
                ],
                2
              )
            : _vm._e(),
          _vm.mapping.field.phoneNumberField
            ? _c(
                "b-form-select",
                {
                  staticClass: "mb-3 mappingText",
                  attrs: { value: _vm.formatConfig.phoneNumberCountryCode },
                  on: { change: _vm.updatePhoneNumberCountryCode }
                },
                [
                  _c(
                    "b-form-select-option",
                    {
                      attrs: { value: _vm.formatConfig.phoneNumberCountryCode }
                    },
                    [
                      _vm.formatConfig.phoneNumberCountryCode
                        ? [
                            _vm._v(
                              "Local Format (" +
                                _vm._s(
                                  _vm.formatConfig.phoneNumberCountryCode
                                ) +
                                ")"
                            )
                          ]
                        : [_vm._v("E.164 Format")]
                    ],
                    2
                  ),
                  !_vm.formatConfig.phoneNumberCountryCode
                    ? _c(
                        "b-form-select-option",
                        {
                          attrs: {
                            value: _vm.$infinityAuth
                              .getProfile()
                              .getCountryCode()
                          }
                        },
                        [
                          _vm._v(
                            "Local Format (" +
                              _vm._s(
                                _vm.$infinityAuth.getProfile().getCountryCode()
                              ) +
                              ")"
                          )
                        ]
                      )
                    : _c("b-form-select-option", { attrs: { value: null } }, [
                        _vm._v("E.164 Format")
                      ])
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "b-col",
        [
          _vm.mapping.field.datetimeField
            ? _c(
                "b-form-select",
                {
                  staticClass: "mb-3 mappingText",
                  attrs: { value: _vm.formatConfig.timezone },
                  on: { change: _vm.updateTimezone }
                },
                [
                  _c(
                    "b-form-select-option",
                    { attrs: { value: _vm.formatConfig.timezone } },
                    [[_vm._v(_vm._s(_vm.formatConfig.timezone))]],
                    2
                  ),
                  _vm._l(
                    _vm.getAvailableTimezones(_vm.formatConfig.timezone),
                    function(timezone) {
                      return _c(
                        "b-form-select-option",
                        { key: timezone, attrs: { value: timezone } },
                        [_vm._v(_vm._s(timezone))]
                      )
                    }
                  )
                ],
                2
              )
            : _vm._e(),
          _vm.mapping.field.phoneNumberField
            ? _c(
                "div",
                { staticClass: "mb-3 mappingText" },
                [
                  _vm.formatConfig.phoneNumberCountryCode
                    ? [
                        _vm._v(
                          "Numbers in " +
                            _vm._s(_vm.formatConfig.phoneNumberCountryCode) +
                            " local format or E.164 will be accepted."
                        )
                      ]
                    : [_vm._v("Only numbers in E.164 format will be accepted.")]
                ],
                2
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "b-col",
        { attrs: { cols: "1" } },
        [
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "test",
                  rawName: "v-test",
                  value: "deleteMatchField",
                  expression: "'deleteMatchField'"
                }
              ],
              staticClass: "mb-3",
              attrs: {
                disabled:
                  _vm.mapping.field.required || _vm.mapping.field.placeholder
              },
              on: { click: _vm.doDelete }
            },
            [_c("b-icon-trash-fill")],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }