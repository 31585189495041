import Vue from 'vue'
import App from '@infinity/intelligent-match/App.vue'
// @ts-ignore
import VueTestAttribute from 'vue-test-attribute'
// @ts-ignore
import wrap from '@vue/web-component-wrapper'
import InfinityPopover from '@infinity/shared/plugins/infinity-popover'
import InfinityTime from '@infinity/shared/plugins/infinity-time'
import InfinityServices from '@infinity/shared/plugins/infinity-services'
import store from '@/store'
import router from '@/router'
import '@infinity/shared/styles/index.scss'

export const WebComponentTag = 'intelligent-match'

Vue.config.productionTip = false

Vue.use(InfinityServices)
Vue.use(VueTestAttribute)
Vue.use(InfinityPopover)
Vue.use(InfinityTime)

Vue.mixin({ store, router })

if (window.customElements.get(WebComponentTag) === undefined) {
  window.customElements.define(WebComponentTag, wrap(Vue, App))
}
