














import { Component, Vue } from 'vue-property-decorator'
import { FormCheckboxPlugin, OverlayPlugin } from 'bootstrap-vue'
import InstallationConfigRepo from '@/repos/InstallationConfigRepo'
import InstallationConfig from '@/models/InstallationConfig'

Vue.use(FormCheckboxPlugin)
Vue.use(OverlayPlugin)

@Component
export default class VoidTriggers extends Vue {
  private installationConfig = new InstallationConfig()
  private isLoading = true

  async created () {
    const repo = new InstallationConfigRepo()
    const config = await repo.find(this.$infinityAuth.getInstallation().getId())

    if (config) {
      this.installationConfig = config
    }

    this.isLoading = false
  }

  async doUpdateValue (value: boolean) {
    this.isLoading = true

    const repo = new InstallationConfigRepo()
    await repo.updateVoidTriggers(this.$infinityAuth.getInstallation().getId(), value)

    this.installationConfig.setVoidTriggers(value)
    this.isLoading = false
  }
}
