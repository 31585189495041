

























import { Component, Vue } from 'vue-property-decorator'
import { BIcon, BIconCloudUploadFill, BIconClockHistory, BIconTools, BNav, BNavItem, LayoutPlugin } from 'bootstrap-vue'
import { OnUploadRoute } from '@/router'

Vue.use(LayoutPlugin)

@Component({
  components: {
    BIconCloudUploadFill,
    BIconClockHistory,
    BIconTools,
    BIcon,
    BNav,
    BNavItem
  }
})
export default class Navigation extends Vue {
  onUploadRoute = OnUploadRoute
}
