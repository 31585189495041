var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    { staticClass: "w-100" },
    [
      _c("b-col", { staticClass: "text-center" }, [
        _c(
          "h4",
          [
            _c("b-icon-cloud-upload-fill", {
              attrs: {
                variant: _vm.$route.name === "Upload File" ? "primary" : null
              }
            }),
            _c("br"),
            _vm._v(" Upload ")
          ],
          1
        )
      ]),
      _c("b-col", { staticClass: "text-center center" }, [
        _c("hr", { staticClass: "mt-4" })
      ]),
      _c("b-col", { staticClass: "text-center" }, [
        _c(
          "h4",
          [
            _c("b-icon-files", {
              attrs: {
                variant: _vm.$route.name === "Mapping" ? "primary" : null
              }
            }),
            _c("br"),
            _vm._v(" Map Data ")
          ],
          1
        )
      ]),
      _c("b-col", { staticClass: "text-center center" }, [
        _c("hr", { staticClass: "mt-4" })
      ]),
      _c("b-col", { staticClass: "text-center" }, [
        _c(
          "h4",
          [
            _c("b-icon", {
              attrs: { icon: _vm.icon, variant: _vm.iconVariant }
            }),
            _c("br"),
            _vm._v(" Review ")
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }