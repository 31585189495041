import Repository from '@infinity/shared/repos/base'
import { Api, JsonData } from '@infinity/shared/helpers/api'
import Mapping from '@/models/Mapping'
import axios, { AxiosResponse } from 'axios'
import { FormatConfig } from '@/views/Mapping.vue'

export default class MappingsRepo extends Repository {
  constructor () {
    super(Api.IM, 'mappings', Mapping, 'data')
  }

  get requestConfig (): { headers: { [k: string]: string | null } } | null {
    const headers = super.requestConfig
    if (!headers) {
      return null
    }

    // Remove as this violates IM CORS
    delete headers.headers['x-auth-token']

    return headers
  }

  async fetchMapping (installationId: string) {
    let response: AxiosResponse

    try {
      response = await axios.get(`${this.api}/${this.apiRoute}`, {
        params: { installationId },
        ...this.requestConfig
      })

      return response.data
    } catch (error: any) {
      if (error.message === ('Request failed with status code 404')) {
        return new this.Model()
      }
    }
  }

  async submitMapping (installationId: string, batchId: string, data: JsonData, config: FormatConfig) {
    // Format the data into the correct shape required by the API
    const mappingData = []
    for (const [key, value] of Object.entries(data)) {
      mappingData.push({ infinityHeader: value, csvHeader: key })
    }
    // make request
    const response = await axios.post(`${this.api}/${this.apiRoute}`,
      {
        batchId, mapping: mappingData, config
      },
      {
        ...this.requestConfig,
        params: {
          installationId
        }
      }
    )

    return response?.data
  }
}
