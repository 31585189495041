
















import { Component, Emit, Prop } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import WebComponentAuthMixin from '@infinity/shared/mixins/web-component-auth'
import WebComponentRootMixin from '@infinity/shared/mixins/web-component-root'
import Navigation from '@infinity/intelligent-match/components/Navigation.vue'
import Status from '@infinity/intelligent-match/components/Status.vue'
import { BToaster } from 'bootstrap-vue'
import { OnUploadRoute } from '@infinity/intelligent-match/router'

@Component({
  components: {
    Navigation,
    Status,
    BToaster
  }
})
export default class App extends mixins(WebComponentRootMixin, WebComponentAuthMixin) {
  @Prop({ type: Boolean, default: false })
  excludeNavigation!: boolean

  onUploadRoute = OnUploadRoute

  async created () {
    await this.waitForBoot()
    this.$store.commit('setInstallationId', this.$infinityAuth.getInstallation().getId())
    this.$store.commit('setUploadRoutesOnly', this.excludeNavigation)
  }

  @Emit('go-to-external-route')
  goToExternalRoute (event: string) {
    return event
  }
}
