import Model, { ApiFieldDefinition, apiToModelTransformer, modelToApiTransformer } from '@infinity/shared/models/base'
import { Api, JsonData } from '@infinity/shared/helpers/api'

export const BatchStatusReadyToDelete = 423
export const BatchStatusDeleted = 410
export const BatchStatusProcessing = 202
export const BatchStatusComplete = 200
export const BatchStatusFailed = 412
export const BatchNotAcceptable = 406

export default class Batch extends Model {
  // TODO: Use Data Dictionary
  protected fieldDefinition: ApiFieldDefinition = {
    [Api.IM]: {
      batchId: 'batchId',
      batchStatus: 'batchStatus',
      uploadUser: 'uploadUser',
      filename: 'filename',
      createDatetime: 'createDatetime',
      matchDatetime: 'matchDatetime',
      DeleteDatetime: 'deleteDatetime',
      startDatetime: 'startDatetime',
      endDatetime: 'endDatetime',
      matchedRows: 'matchedRows',
      totalRows: 'totalRows',
      ftpUpload: 'ftpUpload'
    }
  }

  private batchId = ''
  private batchStatus = 0
  private uploadUser = ''
  private filename = ''
  private createDatetime = ''
  private matchDatetime = ''
  private deleteDatetime = ''
  private startDatetime = ''
  private endDatetime = ''
  private matchedRows: number | null = null
  private totalRows = 0
  private ftpUpload = false

  getBatchId (): string {
    return this.batchId
  }

  setBatchId (batchId: string): Batch {
    this.batchId = batchId

    return this
  }

  getBatchStatus (): number {
    return this.batchStatus
  }

  setBatchStatus (batchStatus: number): Batch {
    this.batchStatus = batchStatus

    return this
  }

  getUploadUser (): string {
    return this.uploadUser
  }

  setUploadUser (uploadUser: string) {
    this.uploadUser = uploadUser
  }

  getFilename (): string {
    return this.filename
  }

  setFilename (filename: string): Batch {
    this.filename = filename

    return this
  }

  getCreateDatetime (): string {
    return this.createDatetime
  }

  setCreateDatetime (createDatetime: string): Batch {
    this.createDatetime = createDatetime

    return this
  }

  getMatchDatetime (): string {
    return this.matchDatetime
  }

  setMatchDatetime (matchDatetime: string): Batch {
    this.matchDatetime = matchDatetime

    return this
  }

  getStartDatetime (): string {
    return this.startDatetime
  }

  setStartDatetime (startDatetime: string): Batch {
    this.startDatetime = startDatetime

    return this
  }

  getEndDatetime (): string {
    return this.endDatetime
  }

  setEndDatetime (endDatetime: string): Batch {
    this.endDatetime = endDatetime

    return this
  }

  getDeleteDatetime (): string {
    return this.deleteDatetime
  }

  setDeleteDatetime (deleteDatetime: string): Batch {
    this.deleteDatetime = deleteDatetime

    return this
  }

  getTotalRows (): number {
    return this.totalRows
  }

  getMatchRate (): string {
    if (this.isProcessing || this.totalRows === 0) {
      return '-'
    }

    if (this.matchedRows !== null && this.matchedRows > 0) {
      return `${this.matchRate}%`
    }

    return '0%'
  }

  setTotalRows (totalRows: number): Batch {
    this.totalRows = totalRows

    return this
  }

  getMatchedRows (): number | null {
    return this.matchedRows
  }

  setMatchedRows (matchedRows: number | null): Batch {
    this.matchedRows = matchedRows

    return this
  }

  getFtpUpload (): boolean {
    return this.ftpUpload
  }

  setFtpUpload (ftpUpload: boolean): Batch {
    this.ftpUpload = ftpUpload

    return this
  }

  get isReadyToDelete (): boolean {
    return this.batchStatus === BatchStatusReadyToDelete
  }

  get isDeleted (): boolean {
    return this.batchStatus === BatchStatusDeleted
  }

  get isProcessing (): boolean {
    return this.batchStatus === BatchStatusProcessing
  }

  get hasFailedState (): boolean {
    return this.batchStatus === BatchStatusFailed
  }

  get hasNotAcceptableState (): boolean {
    return this.batchStatus === BatchNotAcceptable
  }

  get isComplete (): boolean {
    return this.batchStatus === BatchStatusComplete
  }

  get matchRate (): number | null {
    if (this.isProcessing || this.matchedRows === null) {
      return null
    }

    return Math.round((this.matchedRows / this.totalRows) * 100)
  }

  fromApiTransformer (data: JsonData, api: Api): Batch {
    apiToModelTransformer(this, data, this.fieldDefinition[api])

    return this
  }

  toApiTransformer (api: Api): JsonData {
    return modelToApiTransformer(this, this.fieldDefinition[api])
  }
}
