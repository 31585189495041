import Repository from '@infinity/shared/repos/base'
import { Api } from '@infinity/shared/helpers/api'
import FileValidation from '@/models/FileValidation'

export default class ValidationRepo extends Repository {
  constructor () {
    super(Api.IM, 'validate', FileValidation)
  }

  get requestConfig (): { headers: { [k: string]: string | null } } | null {
    const headers = super.requestConfig
    if (!headers) {
      return null
    }

    // Remove as this violates IM CORS
    delete headers.headers['x-auth-token']

    return headers
  }
}
