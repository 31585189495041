var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isValid
        ? _c(
            "b-container",
            {
              directives: [
                {
                  name: "test",
                  rawName: "v-test",
                  value: "results valid",
                  expression: "'results valid'"
                }
              ]
            },
            [
              _c(
                "div",
                { staticClass: "bg-white rounded-lg p-3" },
                [
                  _c(
                    "h5",
                    [
                      _c("b-icon-check-circle-fill", {
                        attrs: { variant: "success" }
                      }),
                      _vm._v(" File uploaded")
                    ],
                    1
                  ),
                  _c("p", [
                    _vm._v(
                      "Your file was uploaded successfully and is now being processed."
                    )
                  ]),
                  _c(
                    "b-button",
                    {
                      staticClass: "mt-4",
                      attrs: { variant: "success" },
                      on: { click: _vm.doReset }
                    },
                    [_vm._v("Upload another file")]
                  )
                ],
                1
              )
            ]
          )
        : _c(
            "b-container",
            {
              directives: [
                {
                  name: "test",
                  rawName: "v-test",
                  value: "results invalid",
                  expression: "'results invalid'"
                }
              ]
            },
            [
              _c(
                "div",
                { staticClass: "bg-white rounded-lg p-3" },
                [
                  _c(
                    "h5",
                    [
                      _c("b-icon-exclamation-diamond-fill", {
                        attrs: { variant: "danger" }
                      }),
                      _vm._v(" Could not upload file")
                    ],
                    1
                  ),
                  _c("p", [
                    _vm._v(
                      "Unfortunately there were errors found in the file you uploaded. Please return to the upload section to try again."
                    )
                  ]),
                  _c("p", [
                    _vm._v("We have listed below the headers "),
                    _vm.rows.length > 0
                      ? _c("span", [
                          _vm._v(
                            "and the first " +
                              _vm._s(_vm.rows.length) +
                              " rows "
                          )
                        ])
                      : _vm._e(),
                    _vm._v("of your file with the errors we have detected.")
                  ]),
                  _vm.csvFile
                    ? _c(
                        "b-table-simple",
                        {
                          staticClass: "resultsTable",
                          attrs: {
                            striped: "",
                            responsive: "",
                            "sticky-header": ""
                          }
                        },
                        [
                          _c(
                            "b-thead",
                            [
                              _c(
                                "b-tr",
                                _vm._l(_vm.headers, function(header, index) {
                                  return _c(
                                    "b-th",
                                    {
                                      key: index,
                                      attrs: {
                                        variant: header.variant,
                                        "sticky-column": index === 0
                                      }
                                    },
                                    [
                                      _c("span", [
                                        header.error
                                          ? _c(
                                              "span",
                                              {
                                                directives: [
                                                  {
                                                    name: "infinity-tooltip",
                                                    rawName:
                                                      "v-infinity-tooltip",
                                                    value: header.error,
                                                    expression: "header.error"
                                                  }
                                                ],
                                                attrs: { title: header.error }
                                              },
                                              [
                                                _c(
                                                  "b-icon-exclamation-triangle-fill",
                                                  {
                                                    attrs: { variant: "danger" }
                                                  }
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm._v(" " + _vm._s(header.value) + " ")
                                      ])
                                    ]
                                  )
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-tbody",
                            _vm._l(_vm.rows, function(row, rowIndex) {
                              return _c(
                                "b-tr",
                                { key: rowIndex },
                                _vm._l(row, function(column, columnIndex) {
                                  return _c(
                                    "b-td",
                                    {
                                      key: columnIndex,
                                      attrs: {
                                        variant: column.variant,
                                        "sticky-column": columnIndex === 0
                                      }
                                    },
                                    [
                                      _c("span", [
                                        column.error
                                          ? _c(
                                              "span",
                                              {
                                                directives: [
                                                  {
                                                    name: "infinity-tooltip",
                                                    rawName:
                                                      "v-infinity-tooltip",
                                                    value: column.error,
                                                    expression: "column.error"
                                                  }
                                                ],
                                                attrs: { title: column.error }
                                              },
                                              [
                                                _c(
                                                  "b-icon-exclamation-triangle-fill",
                                                  {
                                                    attrs: { variant: "danger" }
                                                  }
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm._v(" " + _vm._s(column.value) + " ")
                                      ])
                                    ]
                                  )
                                }),
                                1
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "d-flex justify-content-center mt-3" },
                [
                  _c(
                    "b-button",
                    {
                      attrs: { variant: "primary" },
                      on: { click: _vm.doReset }
                    },
                    [
                      _c("b-icon-arrow-clockwise"),
                      _vm._v(" Try again with a different file")
                    ],
                    1
                  )
                ],
                1
              )
            ]
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }