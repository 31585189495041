var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: { width: "24", height: "24", xmlns: "http://www.w3.org/2000/svg" }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M22 0H4.5a2.002 2.002 0 0 0-2 2v1H2a2.002 2.002 0 0 0-2 2v11.5a2.002 2.002 0 0 0 2 2h5a.5.5 0 0 0 0-1H2a1 1 0 0 1-1-1V5a1.001 1.001 0 0 1 1-1h5.13a1 1 0 0 1 .866.503l.995 1.734A2.498 2.498 0 0 0 11.16 7.5H19a1.001 1.001 0 0 1 1 1v8c-.001.351.091.697.268 1H14a.5.5 0 0 0 0 1h8a2.001 2.001 0 0 0 2-2v-15c0-.66-.942-1.5-2-1.5Zm1 16.5a1 1 0 0 1-2 0v-8a2.002 2.002 0 0 0-2-2h-7.84a1.501 1.501 0 0 1-1.3-.758l-.996-1.735A1.999 1.999 0 0 0 7.13 3H3.5V2a1 1 0 0 1 1-1H22c.4.009.771.208 1 .536V16.5Zm-2.5 4h-6a.5.5 0 0 0-.5-.5h-1.5v-2a.5.5 0 0 0-.5-.5V10a.5.5 0 0 0-.5-.5h-2a.499.499 0 0 0-.5.5v7.5a.5.5 0 0 0-.5.5v2H7a.5.5 0 0 0-.5.5h-6a.5.5 0 0 0-.5.5v2a.5.5 0 0 0 .5.5h6a.499.499 0 0 0 .5.5h7a.5.5 0 0 0 .5-.5h6a.5.5 0 0 0 .5-.5v-2a.5.5 0 0 0-.5-.5ZM10 10.5h1v7h-1v-7Zm-9 12v-1h5.5v1H1Zm6.5.5v-2H9a.5.5 0 0 0 .5-.5v-2h2v2a.5.5 0 0 0 .5.5h1.5v2h-6Zm12.5-.5h-5.5v-1H20v1Z",
          fill: "#0A3749"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }